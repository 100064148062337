import React from "react";
import CrossIcon from "./../../Assets/svgExports/CrossIcon";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/styles";

type CustomDialogPropTypes = DialogProps & {
  handleClose: () => void;
  showCloseButtom?: boolean;
  heading?: string;
  headingTextClass?: string;
  headingContainerClass?: string;
  openAsBottomSheet?: boolean;
};

const StyledDialog = withStyles((theme) => ({
  paper: {
    borderRadius: "8px",
  },
}))(Dialog);

function GenericDialog(props: CustomDialogPropTypes) {
  const {
    children,
    handleClose,
    showCloseButtom,
    heading,
    headingTextClass,
    headingContainerClass,
    openAsBottomSheet = false,
  } = props;

  const handleModalClose = () => {
    handleClose?.();
  };

  return (
    <StyledDialog
      fullWidth={openAsBottomSheet} // to open modal as bottom sheet in webview
      style={
        openAsBottomSheet && {
          // to open modal as bottom sheet in webview
          position: "fixed",
          bottom: 0,
          margin: 0,
        }
      }
      PaperProps={
        openAsBottomSheet && {
          // to open modal as bottom sheet in webview
          style: {
            position: "fixed",
            bottom: 0,
            margin: 0,
            borderRadius: "10px 10px 0 0",
            minWidth: "100vw",
          },
        }
      }
      onClose={handleModalClose}
      {...props}
      aria-labelledby="ds-dialog-title"
    >
      {showCloseButtom && !heading && <CloseButton absolute={true} onClick={handleModalClose} />}
      {heading && !showCloseButtom && 
        <div
          className={`tw-flex tw-items-center tw-justify-between tw-border-b tw-border-solid tw-border-[#111C361F] tw-px-4 tw-py-[1.125rem] tw-font-inter tw-text-base tw-font-semibold ${headingContainerClass}`}
        >
          <p className={`tw-my-0 ${headingTextClass}`}>{heading}</p>
          <CloseButton absolute={false} onClick={handleModalClose} />
        </div>
      }
      {children}
    </StyledDialog>
  );
}

const CloseButton = ({ onClick, absolute }: { onClick: () => void; absolute: boolean }) => {
  return (
    <div
      className={`tw-flex tw-aspect-square tw-h-[22px] tw-w-[22px] tw-items-center tw-justify-center tw-rounded-full hover:tw-bg-[#0000000d] active:tw-bg-[#0000001d] ${absolute ? "!tw-absolute tw-right-3 tw-top-3" : ""}`}
      onClick={onClick}
    >
      <CrossIcon />
    </div>
  );
};

export default GenericDialog;
