import config from "../Config";
export const USER_INFO_API_URL = "/dotk/vm1/settings/getAccountInfo";
export const SET_STORE_USER_INFO = "/dotk/vm1/settings/setStoreUserInfo";
export const GET_ORDER_BANNER_DETAILS_URL = "/dotk/vm1/settings/getOrderBannerDetails";
export const GET_TRANSACTION_LIST_URL = "/dotk/vm1/settings/getTransactionsList";
export const DELETE_STORE_ENDPOINT = "/dotk/vm1/settings/deactivateStore";
export const SET_STORE_TYPE = "/dotk/vm1/settings/setStoreType";
export const GET_SUBSCRIPTIONS_API = "/dotk/vm1/premium/subscriptions";
export const SET_STORE_RFQ_CONFIGS = "/dotk/vm1/settings/storeRfqConfigs";
export const OUT_OF_STOCK_API = "/dotk/vm1/products/outOfStockFlag";
// COUPON API's
export const COUPONS_API = {
  createPromoCodeApi: "/dotk/vm1/settings/createPromoCode",
  createPromoApi: "/dotk/vm1/marketing/promo",
  getAllPromoCodesApi: "/dotk/vm1/settings/getAllMerchantPromoCodes",
  getAllPromoCodesApiV2: "/dotk/vm1/marketing/promo/all",
  updatePromoCodeApi: "/dotk/vm1/settings/updatePromoCodeStatus",
  getPromoCodeDetailsApi: "/dotk/vm1/settings/getCouponDetails?promo_code=",
  getPromoCodeDetailsApiV2: (pathVal) => `/dotk/vm1/marketing/promo/${pathVal}`,
  sharePromoCodeAPi: "/dotk/vm1/settings/shareCoupon?promo_code=",
  getSubscriptionPaymentDetailWithCoupon: "/dotk/vm1/premium/coupon/details/",
};
export const SEND_PAYMENT_LINK = "/dotk/vm1/orders/sendPaymentLink";
// MARKETING API'
export const MARKETING_API = {
  setMarketingTagsApi: "/dotk/vm1/marketing/setStoreMarketingTags",
  getMarketingTagsApi: "/dotk/vm1/marketing/getStoreMarketingTags",
  getCustomerAddressListApi: "/dotk/vm1/settings/getAddressFieldsPageInfo",
  setCustomerAddressListApi: "/dotk/vm1/settings/setStoreAddressConfigs",
};
export const SHARE_PAYMENT_LINK = "/dotk/vm1/orders/sharePaymentLink";

export const GET_STORE_USER_INFO = "/dotk/vm1/settings/getStoreUserPageInfo";
export const VERIFY_DISPLAY_NUMBER_OTP = "/dotk/vm1/settings/verifyDisplayPhoneNumber";
export const LOGIN_AND_UPDATE_PHONE_URL = "/dotk/vm1/user/loginAndUpdatePhone";

// Login/Signup API'
export const LOGIN_API = {
  createStore: "/dotk/vc1/onboard/createStore",
  generateOTP: "/dotk/vo1/user/generateOtp",
  getLoginPageInfo: "/dotk/vo1/onboard/getLoginHelpScreen",
  getOnboardingPageInfo: "/dotk/vm1/onboard/getOnboardingPageInfo",
  invalidateAuthByPhone: (phone) => `/dotk/vm1/user/invalidateAuthByPhone/${phone}`,
  verifyOTP: () => "/dotk/vo1/user/loginV2",
  verifyOTPLess: () => "/dotk/vo1/user/verifyOtplessToken",
};
// BULK UPLOAD API
export const BULK_UPLOAD = {
  bulkUpload: "/dotk/vm1/products/bulkUploadBatch",
  bulkUploadDetails: "/dotk/vm1/products/getBulkUploadBatchDetails",
  getStoreItemsInExcel: "/dotk/vm1/products/getStoreItemsInExcel",
  downloadItemsExcelByStoreId: "/dotk/vm1/products/getStoreItemsExcelByStoreId",
};
// order api
export const UPDATE_ORDER_PARAMETERS_LINK = "/dotk/vm1/delivery/updateOrderParameters";
export const CREATE_SHIPMENT_ORDER_LINK = "/dotk/vm1/delivery/createOrder";
export const REQUEST_ORDER_REPORT_LINK = "/dotk/vm1/orders/requestReport";
export const SET_ORDER_REPORT_SCHEDULER_API = "/dotk/vm1/settings/orderReportSchedularConfig";
export const UPDATE_ORDER_PAYMENT_STATUS_LINK = "/dotk/vm1/orders/updatePaymentStatus";
export const GET_LANDING_PAGE_CARDS_URL = "/dotk/vm1/orders/getLandingPageCards";
export const DELIVERY_AUTHENTICATION_API = {
  deliveryOtpApi: "/dotk/vm1/delivery/generateOtp/",
  deliveryMerchantLoginApi: "/dotk/vm1/delivery/merchantLogin",
};
export const GET_SERVICEABLE_DELIVERY_PARTNER = "/dotk/vm1/delivery/serviceableDeliveryPartner";

export const GET_SEARCH_ORDER_LIST = "/dotk/vm1/orders/getSearchOrdersList";
export const GET_ORDER_REPORT_API = "/dotk/vm1/orders/getOrdersExcelByFilters";
export const SEND_REVIEW_LINK = "/dotk/vm1/orders/reviewComm";
export const GET_DELIVERY_SETUP_INFO_API = "/dotk/vm1/delivery/getDeliverySetupInfo";
export const SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER = "/dotk/vm1/delivery/defaultDeliveryPartner";
export const fetchDeliveryPartnerInputConfigs = (id: number) => `/dotk/vm1/delivery/deliveryPartnerLoginConfigs/${id}`;
export const ADD_DELIVERY_PARTNER = "/dotk/vm1/delivery/merchantLoginV2";

export const SET_STORE_ADDRESS_API = "/dotk/vm1/onboard/setStoreAddress";

export const ADD_OR_UPDATE_STORE_PICKUP_ADDRESS = "/dotk/vm1/delivery/upsertPickupAddress";
export const GET_STORE_PICKUP_ADDRESSES = "/dotk/vm1/delivery/merchantPickupAddressDetails";
export const deleteStorePickupAddressURL = (storeId, addressId) =>
  `/dotk/vm1/delivery/deletePickupAddress/${storeId}/${addressId}`;
export const getServiceabilityByPincodes = (storeId, sourcePincode, destinationPincode) =>
  `/dotk/vm1/delivery/isOrderServiceable?store_id=${storeId}&pickup_pincode=${sourcePincode}&delivery_pincode=${destinationPincode}`;

export const CHECK_WABA_LEAD_REQUEST_STATUS = "/dotk/vm1/marketing/wabaLeadExists";
export const REQUEST_WABA_DEMO = "/dotk/vm1/marketing/wabaLead";
export const GET_WABA_CONFIG_API = "/dotk/vm1/marketing/wabaConfig";
export const UPDATE_WABA_CONFIG_API = "/dotk/vm1/marketing/wabaConfig";
export const PUSH_EVENT_LOG_API = "/dotanalytics/push/pushEventLog";
export const INITIATE_SUBSCRIPTION_PURCHASE = "/dotk/vm1/premium/initiateEcommPayment";
export const INITIATE_WABA_PURCHASE_API = "/dotk/vm1/premium/initiateWabaPurchase";
export const INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON_API = "/dotk/vm1/premium/initiateSubscriptionsPurchase";
export const REQUEST_CALLBACK_FOR_SUBSCRIPTION = "/dotk/vm1/premium/addRequestToCallback";
export const SET_SUBSCRIPTION_CART_URL = "/dotk/vm1/premium/setSubscriptionCart";
export const GET_SUBSCRIPTION_PRICING_LIST_API = "/dotk/vm1/premium/ecommPrices";
export const UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG_API = "/dotk/vm1/settings/serviceSellFlag";
export const GENERATE_INVOICE_API = "/dotk/vm1/premium/generateInvoice";
export const fetchInvoicesUrl = (id: string) => `/dotk/vm1/settings/invoices/${id}`;
export const fetchInvoiceInfoApi = (id: string) => `/dotk/vm1/premium/invoice/${id}`;
export const initiateDomainSubscriptionPurchaseUrl = (id: string) =>
  `/dotk/vm1/premium/initiateSubscriptionPurchase/${id}`;
export const getCfeCtaConfigsApi = (themeClass: number) =>
  `${config.base_url}/dotk/vm1/premium/storeThemeClassConfig?theme_class=${themeClass}`;
export const updateCfeCtaConfigsApi = () => `${config.base_url}/dotk/vm1/premium/storeThemeClassConfig`;
export const FETCH_FEATURE_LOCKS_API = "/dotk/vm1/premium/featureLocks";
// Additional Pages API
export const ADDITIONAL_PAGES_API = {
  getPageDetailsApi: "/dotk/vm1/premium/getPageDetail",
  getPagesListApi: "/dotk/vm1/premium/getPages",
  addNewPageApi: "/dotk/vm1/premium/addNewPage",
  saveTemplateChangesApi: "/dotk/vm1/premium/updateStorePage",
  publishTemplateChangesApi: "/dotk/vm1/premium/updatePageState",
  deletePageApi: "/dotk/vm1/premium/deletePage",
  reorderPageApi: "/dotk/vm1/premium/reorderPages",
};
export const STAFF_MANAGEMENT_API = {
  getAllStaffMembers: "/dotk/vm1/user/getAllStaffMembers",
  addStaffMemberApi: "/dotk/vm1/user/addStaffMember",
  removeStaffMemberApi: "/dotk/vm1/user/removeStaffMember",
  checkStaffExistence: "/dotk/vc1/user/checkStaffExistence",
  getAllPermissionList: "/dotk/vm1/user/getAllPermissionsList",
  updateStaffPermissions: "/dotk/vm1/user/updateStaffPermissions",
  getStaffMemberDetails: "/dotk/vm1/user/staffMember",
  sendStaffInvitation: "/dotk/vm1/user/sendStaffInvitation",
  updateUserinvitation: "/dotk/vc1/user/updateInvitationStatus",
  checkStaffInvite: "/dotk/vc1/user/checkStaffInvite",
  getRequestPermissionApi: "/dotk/vc1/user/getRequestPermissionText/",
};
// Workspace APIs
export const WORKSPACE_API = {
  initiateWorkspacePurchase: "/dotk/vm1/premium/workspace/initiatePurchase",
  getWorkspaceDetails: (domainName: string): string => `/dotk/vm1/premium/workspace/account?domain=${domainName}`,
  provisionAndAddUserMail: "/dotk/vm1/premium/workspace/licenses",
  getWorkspaceEmailUser: (data: Record<string, any>) =>
    `/dotk/vm1/premium/workspace/account/${data.account_id}/license/${data.licence_id}`,
  workspacePriceDetails: (data: Record<string, any>) =>
    `/dotk/vm1/premium/workspace/plans?count=${data.acc_count}&domain=${data.domain}&type=${data?.type}`,
  workspaceRenewalDetails: (data: string) => `/dotk/vm1/premium/workspace/renewalDetails?domain=${data}`,
  initiateWorkspaceRenewal: `/dotk/vm1/premium/workspace/initiateRenewal`,
  getTitanWorkspaceManageURL: (domain: string) => `/dotk/vm1/premium/workspace/ssoUrl?domain=${domain}`,
  ACTIVATE_TITAN_EMAIL: `/dotk/vm1/premium/workspace/titanAccount`,
};
export const SET_GST_API = "/dotk/vm1/settings/setGST";
export const CATALOG_PAGE_API = {
  setItemApi: "/dotk/vm1/products/setItemV2",
  setItemV3: "/dotk/vm1/products/setItemV3",
  setCollectionApi: "/dotk/vm1/products/setCollection",
  updateCollectionStatusApi: "/dotk/vm1/products/updateCollectionStatus",
  getCollectionsApi: "/dotk/vm1/products/collectionCategoryData",
  getItemsInsideClickedCategoryOfCollectionsApi: "/dotk/vm1/products/collectionCategoryItemsData",
  getCategoriesApi: "/dotk/catalog/getCategories",
  getItemsBasicDetails: "/dotk/catalog/getItemsBasicDetails",
  getItemByIdApi: "/dotk/vm1/products/item",
  updateTagApi: "/dotk/vm1/products/updateTag",
  updateTagsRank: "/dotk/vm1/products/updateTagsRank",
  updateItemTagsRanking: "/dotk/vm1/products/updateItemTagsRanking",
  updateItemsRanking: "/dotk/vm1/products/updateItemsRanking",
  removeItemsfromTagApi: "/dotk/vm1/products/removeItemsFromTag",
  quickUpdateItemApi: "/dotk/vm1/products/quickUpdateItem",
  collectionRank: "/dotk/vm1/products/updateCollectionRank",
  getBrandNames: (storeId: number) => `/dotk/vm1/products/brandsList/${storeId}`,
  setOtherCollectionApi: "/dotk/vm1/products/otherCollection",
  getSortFilters: (store_id: number) => `${config.base_url}/dotk/vm1/products/storeItemFilters/${store_id}`,
  setSortFilters: `${config.base_url}/dotk/vm1/products/storeItemFilters`,
  getItemsDetailsByItemIds: "/dotk/vm1/products/getItemsDetailsByItemIds",
  setCollectionCardAnimation: "/dotk/vm1/premium/storePremiumFlag",
  storeServicesFlag: "/dotk/vm1/settings/storeServicesFlag",
  browseCatalogForCoupons: "/dotk/vm1/marketing/browseCatalogForCoupons",
};
export const CART_API = {
  getCartsByFiltersApi: "/dotk/vm1/orders/getCartsByFiltersV2",
  getOrderCartByIdApi: "/dotk/vm1/orders/getOrderCartById",
  sendAbandonedCartReminderApi: "/dotk/vm1/orders/sendAbandonedCartReminder",
  updateCartNotificationApi: "/dotk/vm1/orders/updateCartNotification",
  getFilteredLeadSubscribers: "/dotk/vm1/orders/getFilteredLeadSubscribers",
  requestCartReport: "/dotk/vm1/orders/requestCartReport",
};
export const REQUEST_PREMIUM_CALLBACK = "/dotk/vm1/premium/addRequestToCallback";
// CRM Routes
export const CRM_ROUTES = {
  addOrUpdateCustomer: "/dotk/vm1/marketing/addOrUpdateCustomer",
  getPromoCustomerGroupLists: "/dotk/vm1/marketing/getPromoCustomerGroupLists",
  searchCustomers: "/dotk/vm1/marketing/searchCustomers",
  getFilteredCustomers: "/dotk/vm1/marketing/getFilteredCustomers",
  getStoreAnalytics: `/dotk/vm1/marketing/getStoreAnalyticsBanner`,
  exportCustomerList: `/dotk/vm1/marketing/exportCustomerList`,
  getOrderListByCustomer: `/dotk/vm1/marketing/getOrderListByCustomer`,
  crmExcel: `/dotk/vm1/marketing/crmExcel`,
  whitelistCrmExcel: `/dotk/vm1/marketing/whitelistedCrmExcel`,
  addOrUpdateCustomerFilter: `/dotk/vm1/marketing/addOrUpdateCustomerFilter`,
  updateExcelFile: `/dotk/vm1/marketing/updateExcelFile`,
  getStoreAllListInfo: `/dotk/vm1/marketing/getStoreAllListInfo`,
  getCRMExcelUploadDetails: (excelId: number) => `/dotk/vm1/marketing/getCRMExcelUploadDetails/${excelId}`,
};
// Credit Engine API's
export const CREDIT_ENGINE_API = {
  getStoreCredits: "/dotk/vm1/premium/getCredits",
  addStoreCredits: "/dotk/vm1/premium/addCredits",
  getCreditsTransaction: "/dotk/vm1/premium/getCreditTransactions",
  updateStoreCreditsApi: "/dotk/vm1/premium/updateStoreCredits",
  completePaymentApi: "/dotk/vm1/premium/completePayment",
};
export const DNS_MANAGEMENT_API = {
  getAllData: (domain: string) => `/dotk/vm1/premium/dns/records?domain=${domain}`,
  updateCustomRecords: () => "/dotk/vm1/premium/dns/record",
  deleteCustomRecords: ({ domain, id }) => `/dotk/vm1/premium/dns/record?domain=${domain}&record_id=${id}`,
  updateNameServers: () => "/dotk/vm1/premium/dns/nameServers",
};
// LEAD GENERATION API'S
export const LEAD_GENERATION_API = {
  setLeadGenTemplate: "/dotk/vm1/marketing/setLeadGenTemplate",
  getStoreActiveLeadGenTemplate: (store_id: number) => `/dotk/vo1/settings/getStoreActiveLeadGenTemplate/${store_id}`,
};
// POLICY API
export const STORE_POLICY_API = {
  getAllPolicyPages: (storeId: number) => `/dotk/vm1/settings/getAllPolicyPages/${storeId}`,
  updatePolicyPages: `/dotk/vm1/settings/updatePolicyPages`,
};
// DOMAIN API
export const DOMAIN_API = {
  storeDomains: `/dotk/vm1/premium/storeDomains`,
  domainRenewalDetails: (domainData: any) =>
    `/dotk/vm1/premium/domainRenewalDetails?domain=${domainData.domain}&year=${domainData.year}`,
  initiateDomainRenewal: `/dotk/vm1/premium/initiateDomainRenewal`,
  domainCart: `/dotk/vm1/premium/subscriptionCart`,
  initiateDomainCartPurchase: `/dotk/vm1/premium/initiateCartPurchase`,
  latestDomainDetails: `/dotk/vm1/premium/latestDomain`,
  checkDomainDetails: (domainData: any) => `/dotk/vm1/premium/checkDomainAvailability/${domainData}`,
  addRequestToCallback: `/dotk/vm1/premium/addRequestToCallback/v2`,
  captureLoginMerchantLeads: "/dotk/vo1/onboard/merchantLeads",
};
export const MDR_FLAG_API = `/dotk/vm1/settings/mdrFlag`;
export const STORE_ANALYTICS_API = `/dotk/vm1/orders/getVisitorsSummaryByFilters`;
// GoogleShopping API's
export const GOOGLE_SHOPPING_API = {
  getShoppingAccountDetailsApi: "/dotk/vm1/marketing/getShoppingAccountDetails/",
  getProductAnalysis: "/dotk/vm1/marketing/getProductsAnalytics/",
  checkStoreEligibilityApi: "/dotk/vm1/marketing/checkStoreEligibility",
  checkEligibleProductAPi: "/dotk/vm1/marketing/eligibleProductsNumber/",
  requestManualDemo: "/dotk/vm1/marketing/requestCallback/",
  getStoreServiceDetails: "/dotstore/store/getStoreServicesDetails/",
  createAccountApi: "/dotk/vm1/marketing/createAccount/",
  setShippingDetailsApi: "/dotk/vm1/marketing/setShippingDetails",
  phoneNumberVerificationApi: "/dotk/vm1/marketing/requestPhoneNoVerification",
  verifyOtp: "/dotk/vm1/marketing/verifyPhoneNo",
  syncAllProducts: "/dotk/vm1/marketing/syncAllProducts",
};
export const GOOGLE_SHOPPING_API_V2 = {
  getShoppingAccountDetails: (storeId: number) => `/dotk/vm1/marketing/shopping/accountDetails/${storeId}`,
  createGoogleShopping: (storeId: number) => `/dotk/vm1/marketing/shopping/createAccount/${storeId}`,
  sendShippingDetails: `/dotk/vm1/marketing/shopping/shippingDetails`,
  generateOtp: `/dotk/vm1/marketing/shopping/requestPhoneNoVerification`,
  verifyOtp: `/dotk/vm1/marketing/shopping/verifyPhoneNo`,
  syncAllProductsV2: (storeId: number) => `/dotk/vm1/marketing/shopping/syncAllProducts/${storeId}`,
  getAllProducts: (storeId: number) => `/dotk/vm1/marketing/shopping/productsAnalytics/${storeId}`,
  getStoreAnalytics: (storeId: number) => `/dotk/vm1/marketing/shopping/metrics/${storeId}`,
  autoGenerateItemDescription: (itemId: number) => `/dotk/vm1/products/autoGenerateItemDescription/${itemId}`,
  addItemImage: `/dotk/vm1/products/addItemImage`,
  quickItemUpdate: `/dotk/vm1/products/item`,
};
export const SET_EMI_FLAG = `/dotk/vm1/settings/emiFlag`;
export const FACEBOOK_ADS_API = {
  getFbeAccountDetailsFromFacebook: (externalBusinessId: string, accessToken: string) =>
    `https://graph.facebook.com/${process.env.REACT_APP_FB_API_VERSION}/fbe_business/fbe_installs?fbe_external_business_id=${externalBusinessId}&access_token=${accessToken}`,
  deleteFbeAccount: (externalBusinessId: string, accessToken: string) =>
    `https://graph.facebook.com/${process.env.REACT_APP_FB_API_VERSION}/fbe_business/fbe_installs?fbe_external_business_id=${externalBusinessId}&access_token=${accessToken}`,
  getFeedId: (catalogId: string) =>
    `https://graph.facebook.com/${process.env.REACT_APP_FB_API_VERSION}/${catalogId}/product_feeds`,
  getFbeAccountDetailsViaServer: (storeID: string) => `/dotk/vm1/marketing/getFbAdsIdsByStoreId/${storeID}`,
  setFbeAccountDetailsToServer: "/dotk/vm1/marketing/setFbAdsIds",
  getFbeAdsVisibility: "/dotk/vm1/marketing/fbAdsStatus",
  getUserCatalog: (storeID: string) => `/dotcatalogue/catalog/getFbCatalog/${storeID}`,
};
export const SMS_MARKETING_API = {
  getCampaignListApi: "/dotk/vm1/marketing/getCampaignList",
  getCampaignReportApi: "/dotk/vm1/marketing/getCampaignReport",
  campaignTemplateListApi: "/dotk/vm1/marketing/campaignTemplateList",
  requestedCampaignTemplateApi: "/dotk/vm1/marketing/requestedCampaignTemplate",
  getCampaignCustomersNamesListApi: "/dotk/vm1/marketing/customerListNamesForCampaign",
  getCampaignCustomersContactListApi: "/dotk/vm1/marketing/getCustomerListsForCampaign",
  getCampaignBannerDetailsApi: "/dotk/vm1/marketing/campaignBannerDetails",
  createCampaignApi: "/dotk/vm1/marketing/campaign",
  getCampaignDetails: (campaignId: string) => `/dotk/vm1/marketing/campaignInfo?campaign_id=${campaignId}`,
  sendTestMessageApi: "/dotk/vm1/marketing/sendTestMessage",
  initiateCampaignPurchaseApi: "/dotk/vm1/marketing/initiateCampaignPurchase",
};
export const PAYMENT_API = {
  verifyPayment: (transactionId: string) => `/dotk/vc1/orders/verifyPaymentStatus/${transactionId}`,
  pollPaymentStatus: (transactionId: string) => `/dotk/vc1/orders/pollPaymentStatus/${transactionId}`,
  initiateLazyPayPayment: `/dotk/vc1/settings/initiateLazyPayPayment`,
  verifyLazyPayOtp: `${config.old_base_url}/payment/v2/lazyPay/otp`,
  initiatePayment: `${config.base_url}/dotk/vc1/orders/initiatePayment`,
};
export const CUSTOM_DELIVERY = {
  setCustomDeliveryData: () => `${config.base_url}/dotk/vm1/settings/upsertDeliveryCharges`,
  getCustomDeliveryData: (itemId: number) =>
    `${config.base_url}/dotk/vm1/settings/getDeliveryCharges?item_id=${itemId}`,
  getItemListWithChargesSetup: (storeID: number) =>
    `${config.base_url}/dotk/vm1/settings/getAllCustomItemsInfo/${storeID}`,
  getAllRegions: (flag: number) => `${config.base_url}/dotk/vo1/settings/getAllRegions?flag=${flag}`,
  getAllStatesByRegion: (region: string) => `${config.base_url}/dotk/vo1/settings/getAllStatesByRegion/${region}`,
  getAllCitiesByState: (state: string, isMetroRegion: boolean | undefined) =>
    `${config.base_url}/dotk/vo1/settings/getAllCitiesByState/${state}?is_metro=${isMetroRegion ? 1 : 0}`,
  getAllPincodesByCity: (state: string, isMetroRegion: boolean) =>
    `${config.base_url}/dotk/vo1/settings/getAllPincodesByCity/${state}?is_metro=${isMetroRegion ? 1 : 0}`,
  updateCustomZoneSetup: () => `${config.base_url}/dotk/vm1/settings/upsertCustomDeliveryCharges`,
  getRegionalDetails: (region: string) => `${config.base_url}/dotk/vo1/settings/getRegionalDetails/${region}`,
  getAllCustomZonesDetails: (storeID: string, itemID: number, zoneName: string) =>
    `${config.base_url}/dotk/vm1/settings/getCustomDeliveryCharges?store_id=${storeID}&item_id=${itemID}&zone_name=${
      zoneName || ""
    }`,
  getCustomDeliveryDataStatus: () => `${config.base_url}/dotk/vm1/settings/getCustomDeliveryStatus`,
  enableOrDisableEstimateDeliveryFlag: `${config.base_url}/dotk/vm1/settings/estimatedDeliveryFlag`,
};
export const SEO_TOOLS = {
  getSEODetails: "/dotk/vo1/marketing/getCustomSeoDetails",
  setSEODetails: "/dotk/vm1/marketing/setCustomSeo",
  getAdvSEODetails: "/dotk/vo1/marketing/advancedCustomSeo",
  setAdvSEODetails: "/dotk/vm1/marketing/advancedCustomSeo",
  getAdvSEODetailsPerType: "/dotk/vm1/marketing/seoBasicRecommendations",
};
export const GST_BILLING = {
  getGSTBillingDetails: "/dotk/vm1/settings/gstBillingInfo",
  updateGSTBillingDetails: "/dotk/vm1/settings/updateGstBillingInfo",
  verifyGST: "/dotk/vm1/settings/verifyGST",
  verifyGSTForPurchase: "/dotk/vm1/premium/verifyGstForPurchase",
  enableOrDisableGst: "/dotk/vm1/settings/updateGstEnableFlag",
  requestGSTReport: "/dotk/vm1/settings/requestGstReport",
};
export const CUSTOMER_REVIEW_RATINGS = {
  setCustomerReviewSettings: "/dotk/vm1/products/reviewConfig",
  getCustomerReviewSettings: "/dotk/vo1/settings/reviewConfig",
  getReviewsByStore: "/dotk/vm1/products/reviewsByFilter",
  publishUnpublishReview: "/dotk/vm1/products/publishReview",
};
export const BULK_EDIT_API = {
  getBulkCatalog: `${config.base_url}/dotk/vm1/products/storeItemsDraftItemsDetails`,
  postBulkCatalog: `${config.base_url}/dotk/vm1/products/saveBulkItems`,
  discardDraft: `${config.base_url}/dotk/vm1/products/storeDraftItemsStatus`,
  activeBulkEditCount: `${config.base_url}/dotk/vm1/products/activeBulkEditCount`,
  exportCatalog: `${config.base_url}/dotk/vm1/products/export`,
};
// Endpoints for EMI
export const EMI_API = {
  GET_EMI_OPTIONS_FOR_CARD: `${config.base_url}/dotk/vm1/settings/getEmiOptionsSpecificToCard`,
  GET_ALL_EMI_OPTIONS: `${config.base_url}/dotk/vo1/settings/getAllEmiOptionsInfo`,
  SET_EMI_OPTION: `${config.base_url}/dotk/vo1/orders/emiOption`,
};
export const MEMBER_ONLY = {
  TOGGLE_MEMBER_ONLY: `${config.base_url}/dotk/vm1/products/memberOnlyAccessFlag`,
};

export const HELP_SECTION = {
  GET_HELP_SECTION: `${config.base_url}/dotk/vo1/marketing/helpArticleRoutes`,
};

export const CUSTOMERS_LOGIN = {
  TOGGLE_CUSTOMERS_LOGIN: `${config.base_url}/dotk/vm1/settings/customerLoginFlags`,
};

// Endpoints for brand websites
export const BRAND_WEBSITE_API = {
  GET_BRAND_WEBSITE_LEADS: `${config.base_url}/dotk/vm1/premium/brandWebsiteLeads`,
  GET_BUSINESS_CATEGORIES: `${config.base_url}/dotk/vm1/premium/displayTags/2`,
  getCategoryThemes: (id: number, page: number) =>
    `${config.base_url}/dotk/vm1/premium/tagThemes?type=tag&id=${id}&page=${page}&theme_category=2`,
  getPreviewThemes: (id: number) => `${config.base_url}/dotk/vm1/premium/theme/${id}?theme_category=2`,
  SET_OR_PURCHASE_THEME: `${config.base_url}/dotk/vm1/premium/storeTheme`,
  CUSTOMER_ENQUIRY_REPORT: `${config.base_url}/dotk/vm1/settings/customerEnquiryReport`,
  GET_RENEWAL_PAYMENT_DETAILS: `${config.base_url}/dotk/vm1/premium/initiateBrandWebsitePurchase`,
};

// Endpoints for exit gating
export const EXIT_GATING_API = {
  getExitGatingData: (storeId: number) => `${config.base_url}/dotk/vm1/marketing/exitGating/${storeId}`,
  setExitGatingData: `${config.base_url}/dotk/vm1/marketing/exitGating`,
};

export const SETTING_PAGE_SEARCH_API = {
  searchSetting: (searchedSetting: string) => `${config.base_url}/dotk/vm1/settings/search?query=${searchedSetting}`,
};

export const COMMON_API = {
  GET_APP_CONSTANTS: "/dotk/vm1/premium/storeConstants",
  GET_DLP_APP_CONSTANTS: "/dotk/vo1/domain/landingPageConstants",
  VERIFY_USER_EMAIL: "/dotk/vo1/settings/verifyUserEmail",
  getRestrictionConfig: () => `/dotk/vm1/settings/restrictionConfig`,
};

// utilities page endpoints
export const UTILITIES_PAGE = {
  utilityPageDetails: "/dotk/vm1/onboard/getUtilitiesInfo",
};

export const HYPERVERGE_URL =
  "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.11.0/src/sdk.min.js";
