/**
 * Action Types File
 * Write your all action types here
 */
export * from "./customDelivery";
export * from "./dnsManagement";
export * from "./addAnnouncement";
export * from "./exitGating";

/** Common Action Types */
export const SET_PAGE_LOADER_VISIBILITY = "COMMON/SET_PAGE_LOADER_VISIBILITY";
export const SHOW_PREMIUM_SEARCH_MODAL = "COMMON/SHOW_PREMIUM_SEARCH_MODAL";
export const UPLOAD_IMAGE_TO_S3 = "COMMON/UPLOAD_IMAGE_TO_S3";
export const IS_PREMIUM_SET = "COMMON/IS_PREMIUM_SET";
export const UPLOAD_COMPRESSED_IMAGE_TO_S3 = "COMMON/UPLOAD_COMPRESSED_IMAGE_TO_S3";
export const SET_ALERT_FLOW_DIALOG = "COMMON/SET_ALERT_FLOW_DIALOG";
export const ALERT_MODAL_TOGGLE = "COMMON/ALERT_MODAL_TOGGLE";
export const SAVE_PRODUCT_LOADER_ID = "COMMON/SAVE_PRODUCT_LOADER_ID";
export const SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY = "COMMON/SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY";
export const SET_SELECTED_VARIANT_MODEL_VISIBILITY = "COMMON/SET_SELECTED_VARIANT_MODEL_VISIBILITY";
export const SAVE_DETAILED_ITEM_BY_ID = "COMMON/SAVE_DETAILED_ITEM_BY_ID";
export const SET_VARIANT_MODEL_VISIBILITY = "COMMON/SET_VARIANT_MODEL_VISIBILITY";
export const SET_ADD_PRODUCT_MODAL_VISIBLE = "COMMON/SET_ADD_PRODUCT_MODAL_VISIBLE";
export const IS_BILL_CONTACT_MODAL_VISIBLE = "COMMON/IS_BILL_CONTACT_MODAL_VISIBLE";
export const IS_BILL_SEND_MODAL_VISIBLE = "COMMON/IS_BILL_SEND_MODAL_VISIBLE";
export const IS_CREATE_BILL_MODAL_VISIBLE = "COMMON/IS_CREATE_BILL_MODAL_VISIBLE";
export const SET_TRANSACTION_LIST = "SET_TRANSACTION_LIST";
export const TOGGLE_PAYMENT_MODAL = "COMMON/TOGGLE_PAYMENT_MODAL";
export const TOGGLE_SHIPMENT_DATA_MODAL = "TOGGLE_SHIPMENT_DATA_MODAL";
export const TOGGLE_DELIVERY_TIME_MODAL = "TOGGLE_DELIVERY_TIME_MODAL";
export const TOGGLE_CUSTOMER_PAYMENT_CHOICE_MODAL = "TOGGLE_CUSTOMER_PAYMENT_CHOICE_MODAL";
export const TOGGLE_DELIVERY_PARTNER_DRAWER = "TOGGLE_DELIVERY_PARTNER_DRAWER";
export const TOGGLE_HOW_TO_SHIP_MODAL = "TOGGLE_HOW_TO_SHIP_MODAL";
export const TOGGLE_LAZYPAY_AUTH = "TOGGLE_LAZYPAY_AUTH";
export const COMPLETE_PAYMENT_ACTION = "COMPLETE_PAYMENT_ACTION";
export const SHOW_DOMAIN_SUCCESSFUL_PURCHASE_MODAL = "SHOW_DOMAIN_SUCCESSFUL_PURCHASE_MODAL";
export const GET_ALL_POLICY_PAGES = "GET_ALL_POLICY_PAGES";
export const UPDATE_POLICY_PAGES = "UPDATE_POLICY_PAGES";
export const SAVE_STORE_POLICY_DATA = "SAVE_STORE_POLICY_DATA";
export const TOGGLE_WORKSPACE_EXPIRY_MODAL = "TOGGLE_WORKSPACE_EXPIRY_MODAL";
export const TOGGLE_DOMAIN_RENEWAL_MODAL = "TOGGLE_DOMAIN_RENEWAL_MODAL";
export const SET_TOP_BANNER_VISIBILITY = "SET_TOP_BANNER_VISIBILITY";
export const ECOMM_ELITE_CALLBACK_MODAL_DATA = "COMMON/ECOMM_ELITE_CALLBACK_MODAL_DATA";
export const DICE_LOADER_VISIBILTY = "COMMON/DICE_LOADER_VISIBILTY";
export const AUTO_GENERATE_TEXT_LOADER = "COMMON/AUTO_GENERATE_TEXT_LOADER";
export const OUT_OF_STOCK = "OUT_OF_STOCK";
export const GET_APP_CONSTANTS = "COMMON/GET_APP_CONSTANTS";
export const SAVE_APP_CONSTANTS = "COMMON/SAVE_APP_CONSTANTS";
export const TOGGLE_THEME_UPSELL_MODAL = "TOGGLE_THEME_UPSELL_MODAL";
export const TOGGLE_ORDERS_ONBOARDING_POPUP = "COMMON/TOGGLE_ORDERS_ONBOARDING_POPUP";
/** Auth Action Types */
export const GENERATE_OTP = "AUTH/GENERATE_OTP";
export const VERIFY_OTP = "AUTH/VERIFY_OTP";
export const VERIFY_OPEN_OTP = "AUTH/VERIFY_OPEN_OTP";
export const CREATE_STORE = "AUTH/CREATE_STORE";
export const AUTHENTICATE_USER = "AUTH/AUTHENTICATE_USER";
export const SET_LOGIN_PAGE_INFO = "AUTH/SET_LOGIN_PAGE_INFO";
export const GET_LOGIN_PAGE_INFO = "AUTH/GET_LOGIN_PAGE_INFO";
export const SET_ONBOARDING_DOMAIN_INFO = "AUTH/SET_ONBOARDING_DOMAIN_INFO";
export const GET_ONBOARDING_PAGE_INFO = "AUTH/GET_ONBOARDING_PAGE_INFO";
export const LOGOUT_FROM_ALL_DEVICES = "AUTH/LOGOUT_FROM_ALL_DEVICES";
export const GENERATE_DELIVERY_OTP = "AUTH/GENERATE_DELIVERY_OTP";
export const DELIVERY_MERCHANT_LOGIN = "AUTH/DELIVERY_MERCHANT_LOGIN";
export const OTPLESS_LOGIN_SUCCESS = "AUTH/OTPLESS_LOGIN_SUCCESS";

/** User details Action types */
export const GET_ACCOUNT_INFO = "COMMON/GET_ACCOUNT_INFO";
export const SAVE_USER_ACCOUNT_INFO = "COMMON/SAVE_USER_ACCOUNT_INFO";
export const PUSH_EVENT_LOG = "COMMON/PUSH_EVENT_LOG";

/** Catalog Action Types */
export const TOGGLE_ITEM_AVAILABLITY = "CATALOG/TOGGLE_ITEM_AVAILABLITY"; // for stock on off
export const SET_ACTIVE_CATEGORY_ID = "CATALOG/SET_ACTIVE_CATEGORY_ID";
export const SET_ACTIVE_TAG_ID = "CATALOG/SET_ACTIVE_TAG_ID";
export const SET_ITEM_MOD_STATE = "CATALOG/SET_ITEM_STATE";
export const SAVE_ITEM = "CATALOG/SAVE_ITEM";
export const SAVE_ITEM_V3 = "CATALOG/SAVE_ITEM_V3";
export const SAVE_COLLECTION = "CATALOG/SAVE_COLLECTION";
export const SAVE_CATALOG_COLLECTIONS = "CATALOG/SAVE_CATALOG_COLLECTIONS"; // for saving all collections inside redux store
export const FETCH_CATALOG_COLLECTIONS = "CATALOG/FETCH_CATALOG_COLLECTIONS"; // for fetching all collections
export const SET_CATALOG_ITEM_SEARCH_TEXT = "CATALOG/SET_CATALOG_ITEM_SEARCH_TEXT";
export const FETCH_CATALOG_CATEGORIES = "CATALOG/FETCH_CATALOG_CATEGORIES"; // for fetching all product categories
export const SAVE_CATALOG_CATEGORIES = "CATALOG/SAVE_CATALOG_CATEGORIES"; // for saving all product categories
export const DELETE_CATALOG_ITEM = "CATALOG/DELETE_CATALOGUE_ITEM"; // for delete product item
export const DELETE_CATEGORY = "CATALOG/DELETE_CATEGORY"; // to update category name
export const UPDATE_CATEGORY = "CATALOG/UPDATE_CATEGORY"; // to update category name
export const UPLOAD_IMAGE = "CATALOG/UPLOAD_IMAGE"; // for uploading product image
export const SAVE_REORDERED_CATEGORY = "CATALOG/SAVE_REORDERED_CATEGORY";
export const SAVE_REORDERED_TAG = "CATALOG/SAVE_REORDERED_TAG";
export const GET_INDIVIDUAL_ITEM_DETAILS = "CATALOG/GET_INDIVIDUAL_ITEM_DETAILS";
export const UPDATE_CATALOG_DATA = "CATALOG/UPDATE_CATALOG_DATA";
export const FETCH_RECENTLY_CREATED_VARIANTS = "CATALOG/FETCH_RECENTLY_CREATED_VARIANTS";
export const SAVE_RECENTLY_CREATED_VARIANTS = "CATALOG/SAVE_RECENTLY_CREATED_VARIANTS";
export const FETCH_SUGGESTIVE_VARIANTS = "CATALOG/FETCH_SUGGESTIVE_VARIANTS";
export const SAVE_SUGGESTIVE_VARIANTS = "CATALOG/SAVE_SUGGESTIVE_VARIANTS";
export const SAVE_REORDERED_ITEMS = "CATALOG/SAVE_REORDERED_ITEMS";
export const SAVE_REORDERED_TAG_ITEMS = "CATALOG/SAVE_REORDERED_TAG_ITEMS";
export const SAVE_CATALOG_ITEMS_BASIC_DETAILS = "CATALOG/SAVE_CATALOG_ITEMS_BASIC_DETAILS";
export const SAVE_CLICKED_CATEGORY_ITEM_OF_COLLECTON = "CATALOG/SAVE_CLICKED_CATEGORY_ITEM_OF_COLLECTON";
export const FETCH_ITEMS_BY_ITEM_IDS = "CATALOG/FETCH_ITEMS_BY_ITEM_IDS";
export const UPDATE_COLLECTION_STATUS = "CATALOG/UPDATE_COLLECTION_STATUS";
export const FETCH_CATALOG_ITEMS_BASIC_DETAILS = "CATALOG/FETCH_CATALOG_ITEMS_BASIC_DETAILS";
export const FETCH_ITEMS_INSIDE_CLICKED_CATEGORY_OF_COLLECTIONS =
  "CATALOG/FETCH_ITEMS_INSIDE_CLICKED_CATEGORY_OF_COLLECTIONS";
export const SAVE_CATALOG_ITEMS_BASIC_DETAILS_FOR_REARRANGE = "CATALOG/SAVE_CATALOG_ITEMS_BASIC_DETAILS_FOR_REARRANGE";
export const SAVE_TAG_ITEMS_BASIC_DETAILS_FOR_REARRANGE = "CATALOG/SAVE_TAG_ITEMS_BASIC_DETAILS_FOR_REARRANGE";
export const FETCH_SEARCH_DATA = "CATALOG/FETCH_SEARCH_DATA";
export const REVERT_SEARCHED_DATA = "CATALOG/REVERT_SEARCHED_DATA";
export const SAVE_SEARCHED_DATA = "CATALOG/SAVE_SEARCHED_DATA";
export const SAVE_CATALOG_FOR_CREATE_BILL = "CATALOG/SAVE_CATALOG_FOR_CREATE_BILL";
export const FETCH_CATALOG_ITEM_BY_ID = "CATALOG/FETCH_CATALOG_ITEM_BY_ID";
export const ADD_CATALOG_ITEM = "CATALOG/ADD_CATALOG_ITEM";
export const GET_ITEMS_BY_TAG_ID = "CATALOG/GET_ITEMS_BY_TAG_ID";
export const SAVE_SEARCHED_ITEMS = "CATALOG/SAVE_SEARCHED_ITEMS";
export const FETCH_SEARCHED_ITEMS = "CATALOG/FETCH_SEARCHED_ITEMS";
export const RESET_CATALOG_FOR_CREATE_BILL = "CATALOG/RESET_CATALOG_FOR_CREATE_BILL";
export const UPDATE_TAG = "CATALOG/UPDATE_TAG";
export const REMOVE_ITEM_FROM_TAG = "CATALOG/REMOVE_ITEM_FROM_TAG";
export const QUICK_UPDATE_ITEM = "CATALOG/QUICK_UPDATE_ITEM";
export const UPLOAD_COLLECTION_ORDER = "COLLECTIONS/UPLOAD_COLLECTION_ORDER";
export const GET_BRAND_NAMES = "CATALOG/GET_BRAND_NAMES";
export const SAVE_BRAND_NAMES = "CATALOG/SAVE_BRAND_NAMES";
export const SAVE_OTHERCOLLECTION = "CATALOG/SAVE_OTHERCOLLECTION";
export const GET_SORT_FILTERS = "CATALOG/GET_SORT_FILTERS";
export const SAVE_SORT_FILTERS = "CATALOG/SAVE_SORT_FILTERS";
export const SET_SORT_FILTERS = "CATALOG/SET_SORT_FILTERS";
export const UPDATE_CATALOG_SEARCH_LIST = "CATALOG/UPDATE_CATALOG_SEARCH_LIST";
export const SET_COLLECTION_CARD_ANIMATION = "CATALOG/SET_COLLECTION_CARD_ANIMATION";
export const SET_STORE_SERVICES_FLAG = "CATALOG/SET_STORE_SERVICES_FLAG";
export const BROWSE_CATALOG = "CATALOG/BROWSE_CATALOG";

/** Image Actions */
export const SEARCH_IMAGE = "IMAGE/SEARCH_IMAGE";
export const SAVE_SEARCHED_IMAGE = "IMAGE/SAVE_SEARCHED_IMAGE";

/** Store Action Types */
export const SAVE_STORE_INFO = "STORE/SAVE_STORE_INFO";
export const SET_STORE_SERVICES = "STORE/SET_STORE_SERVICES";
export const UPDATE_STORE_SERVICES = "STORE/UPADTE_STORE_SERVICES";
export const CHANGE_STORENAME = "STORE/CHANGE_STORENAME";
export const GET_DISPLAY_NUMBER_OTP = "STORE/GENERATE_OTP";
export const VERIFY_DISPLAY_NUMBER_OTP = "STORE/VERIFY_DISPLAY_NUMBER_OTP";
export const LOGIN_AND_UPDATE_PHONE = "STORE/LOGIN_AND_UPDATE_PHONE";
export const DELETE_STORE = "STORE/DELETE_STORE";
export const UPDATE_STORE_INFO = "STORE/UPDATE_STORE_INFO";
export const SAVE_STORE_ADDRESS = "STORE/SAVE_STORE_ADDRESS";
export const UPDATE_STORE_ADDRESS = "STORE/UPDATE_STORE_ADDRESS";
export const SET_STORE_LOGO = "STORE/SET_STORE_LOGO";
export const SET_STORE_DESCRIPTION = "STORE/SET_STORE_DESCRIPTION";
export const UPDATE_STORE_BUSINESS = "STORE/UPDATE_STORE_BUSINESS";
export const SET_STORE_INVOICES = "STORE/SET_STORE_INVOICES";
export const FETCH_STORE_INVOICES = "STORE/FETCH_STORE_INVOICES";
export const FETCH_INVOICE_INFO = "STORE/FETCH_INVOICE_INFO";
export const UPDATE_STORE_LINK = "STORE/UPDATE_STORE_LINK";
export const SET_KYC_VERIFICATION = "STORE/SET_KYC_VERIFICATION";
export const SET_GST_NUMBER = "STORE/SET_GST_NUMBER";
export const SET_SDK_RESPONSE = "STORE/SET_SDK_RESPONSE";
export const SET_DELIVERY_INFO = "STORE/SET_DELIVERY_INFO";
export const SET_COD_CHARGES = "STORE/SET_COD_CHARGES";
export const SET_SERVICES = "STORE/SET_SERVICES";
export const SET_ANNOUNCEMENT_INFO = "STORE/SET_ANNOUNCEMENT_INFO";
export const UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG = "STORE/UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG";
export const SUBMIT_SUGGESTION = "STORE/SUBMIT_SUGGESTION";
export const UPDATE_ORDER_CANCELLATION = "STORE/UPDATE_ORDER_CANCELLATION";
export const SET_NOTIFICATIONS = "STORE/SET_NOTIFICATIONS";
export const GET_STORE_CATALOGS_TO_WHATSAPP = "STORE/GET_STORE_CATALOGS_TO_WHATSAPP";
export const SET_STORE_USER_INFO = "SET_STORE_USER_INFO";
export const GET_STORE_USER_INFO = "GET_STORE_USER_INFO";
export const UPDATE_STORE_TYPE = "UPDATE_STORE_TYPE";
export const SET_CUSTOMER_DETAILS_FORM = "SET_CUSTOMER_DETAILS_FORM";
export const GET_CUSTOMER_DETAILS_FORM = "GET_CUSTOMER_DETAILS_FORM";
export const SAVE_CUSTOMER_DETAILS_FORM = "SAVE_CUSTOMER_DETAILS_FORM";
export const TOGGLE_CUSTOMERS_LOGIN = "STORE/TOGGLE_CUSTOMERS_LOGIN";

/** Orders Action Types */
export const FETCH_PENDING_ORDERS = "ORDERS/FETCH_PENDING_ORDERS";
export const REQUEST_ORDER_REPORT = "ORDERS/REQUEST_ORDER_REPORT";
export const SAVE_PENDING_ORDERS = "ORDERS/SAVE_PENDING_ORDERS";
export const EMPTY_PENDING_ORDERS = "ORDERS/EMPTY_PENDING_ORDERS";
export const FETCH_COMPLETED_ORDERS = "ORDERS/FETCH_COMPLETED_ORDERS";
export const SAVE_COMPLETED_ORDERS = "ORDERS/SAVE_COMPLETED_ORDERS";
export const SAVE_DAILY_REPORTS = "ORDERS/SAVE_DAILY_REPORTS";
export const SAVE_WEEKLY_REPORTS = "ORDERS/SAVE_WEEKLY_REPORTS";
export const TOGGLE_ORDER_DETAILS_MODAL = "ORDERS/TOGGLE_ORDER_DETAILS_MODAL";
export const GET_ORDER_DETAILS_BY_ORDER_ID = "ORDERS/GET_ORDER_DETAILS_BY_ORDER_ID";
export const SAVE_ORDER_DETAILS_BY_ORDER_ID = "ORDERS/SAVE_ORDER_DETAILS_BY_ORDER_ID";
export const SAVE_ORDER_REVIEW_DETAILS = "ORDERS/SAVE_ORDER_REVIEW_DETAILS";
export const MARK_ORDER_COMPLETE = "ORDERS/MARK_ORDER_COMPLETE";
export const EMPTY_COMPLETED_ORDERS_LIST = "ORDERS/EMPTY_COMPLETED_ORDERS_LIST";
export const UPDATE_ORDER_STATUS = "ORDERS/UPDATE_ORDER_STATUS";
export const SEND_BILL = "SEND_BILL";
export const SET_ORDER_ITEMS_PAYLOAD = "ORDERS/SET_ORDER_ITEMS_PAYLOAD";
export const MARK_PREPAID_ORDER_COMPLETE = "ORDERS/MARK_PREPAID_ORDER_COMPLETE";
export const MARK_PREPAID_DELIVERY_DATE = "ORDERS/MARK_PREPAID_DELIVERY_DATE";
export const GET_ORDER_DETAILS_BY_ORDER_HASH = "ORDERS/GET_ORDER_DETAILS_BY_ORDER_HASH";
export const SET_ORDER_DETAILS_DATA = "ORDERS/SET_ORDER_DETAILS_DATA";
export const GET_TRANSACTION_DATA_FOR_ORDER = "ORDERS/GE_TRANSACTION_DATA_FOR_ORDER";
export const SAVE_TRANSACTION_DATA_FOR_ORDER = "ORDERS/SAVE_TRANSACTION_DATA_FOR_ORDER";
export const REJECT_ORDER_ACTION = "ORDERS/REJECT_ORDER_ACTION";
export const SHARE_BILL_ACTION = "ORDERS/SHARE_BILL_ACTION";
export const SET_ORDER_DETAILS_OPTIONS_MENU = "ORDERS/SET_ORDER_DETAILS_OPTIONS_MENU";
export const CREATE_ORDER = "ORDERS/CREATE_ORDER";
export const SAVE_ORDER_INFO = "ORDERS/SAVE_ORDER_INFO";
export const GET_SERVICEABLE_DELIVERY_PARTNER = "ORDERS/GET_SERVICEABLE_DELIVERY_PARTNER";
export const SAVE_TRANSACTION_LIST = "SAVE_TRANSACTION_LIST";
export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";
export const SAVE_ORDER_BANNER_DETAILS = "SAVE_ORDER_BANNER_DETAILS";
export const GET_ORDER_BANNER_DETAILS = "GET_ORDER_BANNER_DETAILS";
export const SAVE_ORDER_PROMO_CODE_DETAILS = "ORDERS/SAVE_ORDER_PROMO_CODE_DETAILS";
export const SEND_PAYMENT_LINK = "SEND_PAYMENT_LINK";
export const SAVE_DELIVERY_PARTNER_DETAILS = "SAVE_DELIVERY_PARTNER_DETAILS";
export const SHARE_PAYMENT_LINK = "SHARE_PAYMENT_LINK";
export const UPDATE_ORDER_PARAMETERS = "UPDATE_ORDER_PARAMETERS";
export const CREATE_SHIPMENT_ORDER = "CREATE_SHIPMENT_ORDER";
export const UPDATE_ORDER_PAYMENT_STATUS = "UPDATE_ORDER_PAYMENT_STATUS";
export const SET_ORDER_PAYMENT_TEMP_DATA = "SET_ORDER_PAYMENT_TEMP_DATA";
export const GET_SEARCH_ORDER_LIST = "ORDERS/GET_SEARCH_ORDER_LIST";
export const GET_ORDER_REPORT = "ORDERS/GET_ORDER_REPORT";
export const SEND_REVIEW_LINK = "ORDERS/SEND_REVIEW_LINK";
export const GET_LANDING_PAGE_CARDS = "ORDERS/GET_LANDING_PAGE_CARDS";
export const SAVE_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER =
  "ORDERS/SAVE_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER";
export const GET_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER =
  "ORDERS/GET_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER";
export const SAVE_PREPAID_LIMIT_DATA = "ORDERS/SAVE_PREPAID_LIMIT_DATA";
export const FETCH_ORDERS = "ORDERS/FETCH_ORDERS";
export const SAVE_ORDERS = "ORDERS/SAVE_ORDERS";
export const UPDATE_BULK_ORDERS = "ORDERS/UPDATE_BULK_ORDERS";
export const SET_ORDER_REPORT_SCHEDULER = "ORDERS/SET_ORDER_REPORT_SCHEDULER";
export const GET_ORDER_REPORT_SCHEDULER_CONFIG = "ORDERS/GET_ORDER_REPORT_SCHEDULER_CONFIG";
export const SAVE_ORDER_REPORT_SCHEDULER_CONFIG = "ORDERS/SAVE_ORDER_REPORT_SCHEDULER_CONFIG";

/** BUSINESS TYPES RELATED*/
export const FETCH_BUSSINESS_TYPES = "SPOTLIGHT/FETCH_BUSSINESS_TYPES";
export const SAVE_BUSSINESS_TYPES = "SPOTLIGHT/SAVE_BUSSINESS_TYPES";
export const SUBMIT_BUSINESS = "BUSINESSSELECTION/SUBMIT_BUSINESS";
export const SAVE_BUSINESS_DETAILS = "BUSINESSSELECTION/SAVE_BUSINESS_DETAILS";
export const SHARE_STORE = "SHARE_STORE";
export const SAVE_SHARE_STORE = "SAVE_SHARE_STORE";

/** THEME TYPE RELATED */
export const FETCH_DISPLAY_TAGS = "THEMES/FETCH_DISPLAY_TAGS";
export const SAVE_DISPLAY_TAGS = "THEMES/SAVE_DISPLAY_TAGS";
export const SEARCH_DISPLAY_TAGS = "THEMES/SEARCH_DISPLAY_TAGS";
export const SAVE_SEARCH_TAGS = "THEMES/SAVE_SEARCH_TAGS";
export const FETCH_DISPLAY_THEMES = "THEMES/FETCH_DISPLAY_THEMES";
export const SAVE_DISPLAY_THEMES = "THEMES/SAVE_DISPLAY_THEMES";
export const EMPTY_DISPLAY_THEMES = "THEMES/EMPTY_DISPLAY_THEMES";
export const GET_SUBSCRIPTION_DETAILS = "THEMES/GET_SUBSCRIPTION_DETAILS";
export const SAVE_SUBSCRIPTION_DETAILS = "THEMES/SAVE_SUBSCRIPTION_DETAILS";
export const SET_THEME = "THEMES/SET_THEME";
export const UPDATE_THEME_DATA = "THEMES/UPDATE_THEME_DATA";
export const DEACTIVATE_STORE_THEME = "THEMES/DEACTIVATE_STORE_THEME";
export const GET_ALL_PRESET_COLORS = "THEMES/GET_ALL_PRESET_COLORS";
export const SAVE_ALL_PRESET_COLORS = "THEMES/SAVE_ALL_PRESET_COLORS";
export const SET_STORE_THEME_COLOR_PALETTE = "THEMES/SET_STORE_THEME_COLOR_PALETTE";
export const UPLOAD_IMAGE_LINKS = "THEMES/UPLOAD_IMAGE_LINKS";
export const RESET_IMAGE_LINK = "THEME/RESET_IMAGE_LINK";
export const REORDER_STORE_THEME_BANNER = "THEME/REORDER_STORE_THEME_BANNER";
export const REMOVE_STORE_THEME_BANNER = "THEME/REMOVE_STORE_THEME_BANNER";
export const REQUEST_PREMIUM_CALLBACK_ACTION = "THEME/REQUEST_PREMIUM_CALLBACK_ACTION";
export const UPDATE_LOGO_AND_NAME_VISIBILITY = "THEME/UPDATE_LOGO_AND_NAME_VISIBILITY";

/** PAYMENT TYPE RELATED */
export const SAVE_PRE_PAYMENT_DATA = "THEMES/SAVE_PRE_PAYMENT_DATA";
export const SAVE_PAYMENT_INFORMATIONS = "THEMES/SAVE_PAYMENT_INFORMATIONS";
export const INIT_PAYMENT_FOR_QR = "THEMES/INIT_PAYMENT_FOR_QR";
export const INIT_PAYMENT = "THEMES/INIT_PAYMENT";
export const POLL_PAYMENT_STATUS = "THEMES/POLL_PAYMENT_STATUS";
export const SAVE_POST_PAYMENT_DATA = "PAYMENT/SAVE_POST_PAYMENT_DATA";
export const VERIFY_PAYMENT = "PAYMENT/VERIFY_PAYMENT";
export const SAVE_PAYMENT_MODES_DATA = "SAVE_PAYMENT_MODES_DATA";
export const FETCH_PAYMENT_MODES = "FETCH_PAYMENT_MODES";
export const SET_PAYMENT_OPTIONS = "SET_PAYMENT_OPTIONS";
export const PAYMENT_OPTIONS_CHANGE = "PAYMENT_OPTIONS_CHANGE";
export const PAYMENT_METHOD_CHANGE = "PAYMENT_METHOD_CHANGE";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const GET_PAYMENT_MODES_BY_MERCHANT_ID = "GET_PAYMENT_MODES_BY_MERCHANT_ID";
export const SET_ACTIVE_PAYMENT_METHODS = "SET_ACTIVE_PAYMENT_METHODS";
export const INIT_LAZY_PAY_PAYMENT = "INIIATE_LAZY_PAY_PAYMENT";
export const VERIFY_LAZY_PAY_OTP = "PAYMENT/VERIFY_LAZY_PAY_OTP";
export const SET_MDR_FLAG = "PAYMENT/SET_MDR_FLAG";
export const TOGGLE_EMI_FLAG = "PAYMENT/TOGGLE_EMI_FLAG";
export const GET_CFE_CTA_CONFIGS = "THEME/GET_CFE_CTA_CONFIGS";
export const SAVE_CFE_CTA_CONFIGS = "THEME/SAVE_CFE_CTA_CONFIGS";
export const UPDATE_CFE_CTA_CONFIGS = "THEME/UPDATE_CFE_CTA_CONFIGS";
export const SET_PARTIAL_PAYMENT_DATA = "PAYMENT/UPDATE_PARTIAL_PAYMENT_DATA";
export const VERIFY_GST_FOR_PURCHASE = "PAYMENT/VERIFY_GST_FOR_PURCHASE";
export const GET_EMI_OPTIONS_FOR_CARD = "EMI/GET_EMI_OPTIONS_FOR_CARD";
export const GET_ALL_EMI_OPTIONS = "EMI/GET_ALL_EMI_OPTIONS";
export const SAVE_EMI_OPTION_FOR_CARD = "EMI/SAVE_EMI_OPTION_FOR_CARD";
export const SAVE_ALL_EMI_OPTIONS = "EMI/SAVE_ALL_EMI_OPTIONS";
export const SET_EMI_OPTION = "EMI/SET_EMI_OPTION";

/** DOMAIN TYPE RELATED */
export const SEARCH_DOMAIN = "DOMAIN/SEARCH_DOMAIN";
export const SEARCH_OPEN_DOMAIN = "DOMAIN/SEARCH_OPEN_DOMAIN";
export const DEFAULT_SEARCH_DOMAIN = "DOMAIN/DEFAULT_SEARCH_DOMAIN";
export const SAVE_DOMAIN_LIST = "DOMAIN/SAVE_DOMAIN_LIST";
export const SAVE_SEARCH_DOMAIN_LIST = "DOMAIN/SAVE_SEARCH_DOMAIN_LIST";
export const CHECK_DOMAIN_AVAILABLE = "DOMAIN/CHECK_DOMAIN_AVAILABLE";
// export const SAVE_TRANSFORMED_DOMAIN_LIST = "DOMAIN/SAVE_TRANSFORMED_DOMAIN_LIST";
export const INITIATE_DOMAIN_PURCHASE = "DOMAIN/INITIATE_DOMAIN_PURCHASE";
export const GET_DOMAIN_DETAILS = "DOMAIN/GET_DOMAIN_DETAILS";
export const SAVE_DOMAIN_DETAILS = "DOMAIN/SAVE_DOMAIN_DETAILS";
export const INITIATE_DOMAIN_SETUP_PURCHASE = "DOMAIN/INITIATE_DOMAIN_SETUP_PURCHASE";
export const FETCH_DOMAIN_CART = "DOMAIN/FETCH_DOMAIN_CART";
export const SAVE_DOMAIN_CART = "DOMAIN/SAVE_DOMAIN_CART";
export const UPDATE_DOMAIN_CART = "DOMAIN/UPDATE_DOMAIN_CART";
export const INITIATE_DOMAIN_CART_PURCHASE = "DOMAIN/INITIATE_DOMAIN_CART_PURCHASE";
export const CAPTURE_DLP_MERCHANT_LEADS = "DOMAIN/CAPTURE_DLP_MERCHANT_LEADS";
// export const SAVE_SELECTED_DOMAIN_DETAIL = "DOMAIN/SAVE_SELECTED_DOMAIN_DETAIL";

/** BANK DETAILS TYPE RELATED */
export const FETCH_BANK_ACCOUNT_DETAILS = "COMMON/FETCH_BANK_ACCOUNT_DETAILS";
export const SAVE_BANK_ACCOUNT_DETAILS = "COMMON/SAVE_BANK_ACCOUNT_DETAILS";
export const SUBMIT_BANK_ACCOUNT_DETAILS = "COMMON/SUBMIT_BANK_ACCOUNT_DETAILS";

/** CART TYPE RELATED */
export const ADD_TO_CART = "COMMON/ADD_TO_CART";
export const GET_CARTS_BY_FILTER = "CART/GET_CARTS_BY_FILTER";
export const GET_ORDER_CART_BY_ID = "CART/GET_ORDER_CART_BY_ID";
export const CART_DETAILS_BY_ID = "CART/CART_DETAILS_BY_ID";
export const SEND_ABANDONED_CART_REMINDER = "CART/SEND_ABANDONED_CART_REMINDER";
export const UPDATE_CART_NOTIFICATION = "CART/UPDATE_CART_NOTIFICATION";
export const GET_FILTERED_EMAIL_SUBSCRIBERS = "CART/GET_FILTERED_EMAIL_SUBSCRIBERS";
export const REQUEST_CART_REPORT = "CART/REQUEST_CART_REPORT";
export const IS_LOADING = "CART/IS_LOADING";

/** ADS TYPE RELATED */
export const FETCH_ADS_LIST = "ADS/FETCH_ADS_LIST";
export const SEARCH_AD_LOCATIONS = "ADS/SEARCH_AD_LOCATIONS";
export const FETCH_AD_KEYWORDS = "ADS/FETCH_AD_KEYWORDS";
export const SAVE_AD = "ADS/SAVE_AD";
export const CREATE_AD = "ADS/CREATE_AD";
export const INITIATE_AD_PURCHASE = "ADS/INITIATE_AD_PURCHASE";
export const FETCH_AD_DETAILS = "ADS/FETCH_AD_DETAILS";
export const UPDATE_AD_STATE = "ADS/UPDATE_AD_STATE";
export const SAVE_ADS_LIST = "ADS/SAVE_ADS_LIST";
export const SAVE_AD_DETAILS = "ADS/SAVE_AD_DETAILS";
export const SAVE_AD_KEYWORDS = "ADS/SAVE_AD_KEYWORDS";
export const SAVE_SEARCHED_AD_LOCATIONS = "ADS/SAVE_SEARCHED_AD_LOCATIONS";
export const TOGGLE_AD_LOCATION_MODAL = "ADS/TOGGLE_AD_LOCATION_MODAL";
export const TOGGLE_PAUSE_RESUME_MODAL = "ADS/TOGGLE_PAUSE_RESUME_MODAL";

/** MARKETING RELATED  */
export const SET_MARKETING_TAG = "MARKETING/SET_MARKETING_TAG_ACTION";
export const GET_MARKETING_TAG = "MARKETING/GET_MARKETING_TAG_ACTION";
export const SAVE_MARKETING_TAGS = "MARKETING/SAVE_MARKETING_TAGS";
export const GET_CUSTOMER_ADDRESS_LIST = "MARKETING/GET_CUSTOMER_ADDRESS_LIST";
export const SET_CUSTOMER_ADDRESS_LIST = "MARKETING/SET_CUSTOMER_ADDRESS_LIST";
export const SAVE_ADDRESS_LIST = "MARKETING/SAVE_ADDRESS_LIST";

/** SUBSCRIPTION RELATED */
export const INITIATE_SUBSCRIPTION_PURCHASE = "SUBSCRIPTION/INITIATE_SUBSCRIPTION_PURCHASE";
export const INITIATE_WABA_PURCHASE = "SUBSCRIPTION/INITIATE_WABA_PURCHASE";
export const TOGGLE_SUBSCRIPTION_FLOW_MODAL = "SUBSCRIPTION/TOGGLE_SUBSCRIPTION_FLOW_MODAL";
export const REQUEST_CALLBACK = "SUBSCRIPTION/REQUEST_CALLBACK";
export const SET_REQUEST_CALLBACK_MODAL = "SUBSCRIPTION/SET_REQUEST_CALLBACK_MODAL";
export const SET_SUBSCRIPTION_AMOUNT = "SUBSCRIPTION/SET_SUBSCRIPTION_AMOUNT";
export const SET_SUBSCRIPTION_CART = "SUBSCRIPTION/SET_SUBSCRIPTION_CART";
export const SAVE_SUBSCRIPTION_CART_SUCCESS_ID = "SUBSCRIPTION/SAVE_SUBSCRIPTION_CART_SUCCESS_ID";
export const INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE = "SUBSCRIPTION/INITIATE_DOMAIN_SUBSCRIPTION_PURCHASE";
export const SAVE_ADDONS_PURCHASED = "SUBSCRIPTION/SAVE_ADDONS_PURCHASED";
export const GET_SUBSCRIPTION_PRICING_LIST = "SUBSCRIPTION/GET_SUBSCRIPTION_PRICING_LIST";
export const SET_SUBSCRIPTION_PRICING_LIST = "SUBSCRIPTION/SET_SUBSCRIPTION_PRICING_LIST";
export const TOGGLE_SUBSCRIPTION_EXPIRE_MODAL = "SUBSCRIPTION/TOGGLE_SUBSCRIPTION_EXPIRE_MODAL";
export const FETCH_FEATURE_LOCKS = "SUBSCRIPTION/FETCH_FEATURE_LOCKS";
export const SAVE_FEATURE_LOCKS = "SUBSCRIPTION/SAVE_FEATURE_LOCKS";
export const GENERATE_INVOICE = "SUBSCRIPTION/GENERATE_INVOICE";
export const INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON = "SUBSCRIPTION/INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON";

/** WORKSPACE RELATED */
export const INITIATE_WORKSPACE_PAYMENT = "WORKSPACE/INITIATE_WORKSPACE_PAYMENT";
export const GET_WORKSPACE_DETAILS = "WORKSPACE/GET_WORKSPACE_DETAILS";
export const SAVE_WORKSPACE_DETAILS = "WORKSPACE/SAVE_WORKSPACE_DETAILS";
export const PROVISION_ADD_USER_EMAIL = "WORKSPACE/PROVISION_ADD_USER_EMAIL";
export const GET_WORKSPACE_EMAIL_USER_BY_ID = "WORKSPACE/GET_WORKSPACE_EMAIL_USER_BY_ID";
export const SAVE_WORKSPACE_EMAIL_USER_BY_ID = "WORKSPACE/SAVE_WORKSPACE_EMAIL_USER_BY_ID";
export const SET_INITIAL_NO_OF_EMAILS_BOUGHT = "WORKSPACE/SET_INITIAL_NO_OF_EMAILS_BOUGHT";
export const TOGGLE_WORKSPACE_MODAL = "WORKSPACE/TOGGLE_WORKSPACE_MODAL";
export const SET_ACCOUNT_TYPE = "WORKSPACE/SET_ACCOUNT_TYPE";
export const SET_MERGED_ACCOUNTS = "WORKSPACE/SET_MERGED_ACCOUNTS";
export const GET_WORKSPACE_PRICE_DETAILS = "WORKSPACE/GET_WORKSPACE_PRICE_DETAILS";
export const SAVE_WORKSPACE_PRICE_DETAILS = "WORKSPACE/SAVE_WORKSPACE_PRICE_DETAILS";
export const WORKSPACE_UPSELL_MODAL = "WORKSPACE/WORKSPACE_UPSELL_MODAL";
export const NUMBER_OF_NEW_ACCOUNTS_CREATED = "WORKSPACE/NUMBER_OF_NEW_ACCOUNTS_CREATED";
export const GET_WORKSPACE_RENEWAL_DETAILS = "WORKSPACE/GET_WORKSPACE_RENEWAL_DETAILS";
export const SAVE_WORKSPACE_RENEWAL_DETAILS = "WORKSPACE/SAVE_WORKSPACE_RENEWAL_DETAILS";
export const TOGGLE_WORKSPACE_RENEWAL_MODAL = "WORKSPACE/TOGGLE_WORKSPACE_RENEWAL_MODAL";
export const INITIATE_WORKSPACE_RENEWAL = "WORKSPACE/INITIATE_WORKSPACE_RENEWAL";
export const GET_TITAN_WORKSPACE_MANAGE_URL = "WORKSPACE/GET_TITAN_WORKSPACE_MANAGE_URL";
export const ACTIVATE_WORKSPACE_MANAGE_URL = "WORKSPACE/ACTIVATE_WORKSPACE_MANAGE_URL";

/** LEADGEN RELATED */
export const SET_LEADGEN_TEMPLATE = "LEAD/SET_LEADGEN_TEMPLATE";
export const GET_STORE_ACTIVE_LEADGEN_TEMPLATE = "LEAD/GET_STORE_ACTIVE_LEADGEN_TEMPLATE";
export const SET_STORE_LEADGEN_TEMPLATES = "LEAD/SET_STORE_LEADGEN_TEMPLATES";

/** FACEBOOK BUSINESS EXTENSION */
export const GET_FBE_ADS_VISIBILITY_STATUS = "FBE/GET_FBE_ADS_VISIBILITY_STATUS";
export const SET_FBE_ADS_VISIBILITY_STATUS = "FBE/SET_FBE_ADS_VISIBILITY_STATUS";
export const GET_FBE_ACCOUNT_DETAILS = "FBE/GET_FBE_ACCOUNT_DETAILS";
export const SAVE_FBE_ACCOUNT_DETAILS = "FBE/SAVE_FBE_ACCOUNT_DETAILS";
export const GET_FBE_ACCOUNT_DETAILS_FROM_SERVER = "FBE/GET_FBE_ACCOUNT_DETAILS_FROM_SERVER";
export const SET_FBE_ACCOUNT_DETAILS_ON_SERVER = "FBE/SET_FBE_ACCOUNT_DETAILS_ON_SERVER";
export const CLEAR_FBE_ACCOUNT_DETAILS = "FBE/CLEAR_FBE_ACCOUNT_DETAILS";
export const DELETE_FBE_ACCOUNT = "FBE/DELETE_FBE_ACCOUNT";
export const GET_FBE_FEED_ID = "FBE/GET_FBE_FEED_ID";
export const GET_FBE_USER_CATALOG = "FBE/GET_FBE_USER_CATALOG";

/** Domain Renewal */
export const STORE_DOMAINS = "DOMAIN/STORE_DOMAINS";
export const INITIATE_DOMAIN_RENEWAL = "DOMAIN/INITIATE_DOMAIN_RENEWAL";
export const DOMAIN_RENEWAL_DETAILS = "DOMAIN/DOMAIN_RENEWAL_DETAILS";
export const SAVE_ALL_DOMAIN_DETAILS = "DOMAIN/SAVE_ALL_DOMAIN_DETAILS";
export const GET_LATEST_DOMAIN_PURCHASE_DETAILS = "DOMAIN/GET_LATEST_DOMAIN_PURCHASE_DETAILS";
export const SAVE_LATEST_DOMAIN_PURCHASE_DETAILS = "DOMAIN/SAVE_LATEST_DOMAIN_PURCHASE_DETAILS";
export const SAVE_DOMAIN_RENEWAL_DETAILS = "DOMAIN/SAVE_DOMAIN_RENEWAL_DETAILS";
export const SAVE_DOMAIN_RENEWAL_EXPIRY = "DOMAIN/SAVE_DOMAIN_RENEWAL_EXPIRY";
export const SAVE_DOMAIN_YEAR = "DOMAIN/SAVE_DOMAIN_YEAR";

/* Customer Review Ratings */
export const SET_CUSTOMER_REVIEW_SETTINGS = "SET_CUSTOMER_REVIEW_SETTINGS";
export const GET_CUSTOMER_REVIEW_SETTINGS = "GET_CUSTOMER_REVIEW_SETTINGS";
export const SAVE_REVIEW_SETTINGS = "SAVE_REVIEW_SETTINGS";
export const SAVE_REVIEWS_BY_STORE = "SAVE_REVIEWS_BY_STORE";
export const GET_CUSTOMER_REVIEWS_BY_STORE = "GET_CUSTOMER_REVIEWS_BY_STORE";
export const PUBLISH_UNPUBLISH_REVIEW = "PUBLISH_UNPUBLISH_REVIEW";

/** Social Media Profiles */
export const ADD_SOCIAL_MEDIA_PROFILE = "SOCIAL_MEDIA/ADD_SOCIAL_MEDIA_PROFILE";
export const FETCH_SOCIAL_MEDIA_PROFILE = "SOCIAL_MEDIA/FETCH_SOCIAL_MEDIA_PROFILE";
export const DELETE_SOCIAL_MEDIA_PROFILE = "SOCIAL_MEDIA/DELETE_SOCIAL_MEDIA_PROFILE";
export const EDIT_SOCIAL_MEDIA_PROFILE = "SOCIAL_MEDIA/EDIT_SOCIAL_MEDIA_PROFILE";
export const UPDATE_STORE_SOCIAL_MEDIA_DETAILS = "SOCIAL_MEDIA/UPDATE_STORE_SOCIAL_MEDIA_DETAILS";

// Whatsapp Marketing
export const TOGGLE_REQUEST_CONFIRM_MODAL = "WABA/TOGGLE_REQUEST_CONFIRM_MODAL";
export const SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST = "WABA/SUBMIT_WHATSAPP_MARKETING_DEMO_REQUEST";
export const UPDATE_WHATSAPP_MARKETING_REQUEST_STATUS = "WABA/UPDATE_WHATSAPP_MARKETING_REQUEST_STATUS";
export const GET_WHATSAPP_MARKETING_REQUEST_STATUS = "WABA/GET_WHATSAPP_MARKETING_REQUEST_STATUS";
export const UPDATE_WABA_CONFIG = "WABA/UPDATE_WABA_CONFIG";
export const SET_WABA_CONFIG = "WABA/SET_WABA_CONFIG";
export const GET_WABA_CONFIG = "WABA/GET_WABA_CONFIG";

/* Product Inquiry Notify Me */
export const FETCH_PRODUCT_INQUIRY = "PRODUCT_INQUIRY/FETCH_PRODUCT_INQUIRY";
export const SAVE_PRODUCT_INQUIRY = "PRODUCT_INQUIRY/SAVE_PRODUCT_INQUIRY";
export const TOGGLE_PRODUCT_INQUIRY_ENABLE = "PRODUCT_INQUIRY/TOGGLE_PRODUCT_INQUIRY_ENABLE";
export const FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS =
  "PRODUCT_ENQUIRY/FETCH_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS";
export const SAVE_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS = "PRODUCT_ENQUIRY/SAVE_PRODUCT_ENQUIRY_ENABLE_DISABLE_STATUS";
export const ENABLE_PRODUCT_INQUIRY_FEATURE = "PRODUCT_ENQUIRY/ENABLE_PRODUCT_INQUIRY_FEATURES";
export const SAVE_PRODUCT_ENQUIRY_TOTAL_PAGES = "PRODUCT_ENQUIRY/SAVE_PRODUCT_ENQUIRY_TOTAL_PAGES";
export const SET_PRODUCT_INQUIRY_TAB = "PRODUCT_ENQUIRY/SET_PRODUCT_INQUIRY_TAB";
export const SET_PRODUCT_FILTERED_DATA = "PRODUCT_ENQUIRY/SET_PRODUCT_FILTERED_DATA";

/** Members only */
export const TOGGLE_MEMBERS_ONLY = "SUBSCRIPTION/TOGGLE_MEMBERS_ONLY";

export const SEND_DOMAIN_CALLBACK_REQUEST = "CALLBACKREQUEST/SEND_CALLBACK_REQUEST";

/**  Setting Page Search  */

export const GET_SEARCH_SETTING_DATA = "SEARCHSETTING/GET_SEARCH_SETTING_DATA";
export const SAVE_SEARCH_SETTING_DATA = "SEARCHSETTING/SAVE_SEARCH_SETTING_DATA";
export const SAVE_SEARCHED_QUERY_ID = "SEARCHSETTING/SAVE_SEARCHED_QUERY_ID";
export const SAVE_ORDER_PRESENT_STATUS = "SEARCHSETTING/SAVE_ORDER_PRESENT_STATUS";
