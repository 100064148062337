import { saveDomainDetails } from "./../Actions/domain";
import { saveAllPresetColors, saveCfeCtaConfigs } from "./../Actions/theme";
import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import config from "./../../Config";
import * as themeTypes from "./../ActionTypes";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import {
  savePrePaymentData,
  setPageLoaderVisibility,
  saveDisplayTags,
  saveDisplayThemes,
  emptyDisplayThemes,
  saveSubscriptionDetails,
  saveSearchTags,
  saveUserSubscriptionDetails,
  updataWhatsappMarketingRequestStatus,
} from "../Actions";
import history from "../../Utils/history";
import {
  getCfeCtaConfigsApi,
  GET_SUBSCRIPTIONS_API,
  REQUEST_PREMIUM_CALLBACK,
  updateCfeCtaConfigsApi,
} from "../../Services/apiUrls";
import { setPremiumPurchaseType, setPrePaymentDataToSessionStorage, appendCreditUsedKey } from "../../Utils/_helper";
import { PREMIUM_PURCHASE_TYPE_CONSTANTS } from "../../Constants";
import { ECOMM_ELITE, ECOMM_PLUS } from "../../Constants/subscription";

function* fetchDisplayTags() {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/premium/displayTags/1`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(
        saveDisplayTags({
          businessTypes: response?.data?.data?.business_types,
          colors: response?.data?.data?.colors,
        }),
      );
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went wrong");
  }
}

function* fetchDisplayThemes(actions: any) {
  yield put(setPageLoaderVisibility(true));
  if (actions.data.page === 1) {
    yield put(emptyDisplayThemes());
  }
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/premium/tagThemes?type=${actions.data.type}&id=${actions.data.id}&page=${actions.data.page}&theme_category=1`,
      parseToJson: true,
    });
    if (response && response.status) {
      yield put(
        saveDisplayThemes({
          themes: response?.data?.data?.themes,
          isNext: response?.data?.data?.is_next_page,
        }),
      );
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getSubscriptionDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: GET_SUBSCRIPTIONS_API,
      parseToJson: true,
    });

    if (response?.data?.status) {
      const data = response.data.data;
      yield put(
        saveSubscriptionDetails({
          status: data.theme ? response?.data?.status : false,
          ...data,
          subscription_data: data.theme || {},
          additional_page: data.additional_page || null,
          youtube_link: data.youtube_link || null,
          workspace_details: data.workspace || null,
          theme: data.theme || null,
          multibanner: data?.multibanner || null,
          abandoned_cart:
            data?.subscription?.subscription_type === ECOMM_PLUS ||
            data?.subscription?.subscription_type === ECOMM_ELITE,
          domain: data?.domain || null,
          gst: data?.gst || null,
          customerReviewRatingSubscription: data?.customer_reviews || null,
          waba: data?.waba || null,
          brandWebsiteSubscription: data?.brand_website || null,
          ecomSubscription: data?.subscription || null,
          upcomingEcomSubscription: data?.upcoming_ecomm_subscription || null,
          externalDomain: data?.external_domain || null,
        }),
      );
      yield put(saveUserSubscriptionDetails(data?.subscription || null));
      yield put(updataWhatsappMarketingRequestStatus({ isWabaSubscriptionPurchased: data?.waba || false }));
      const domainDetails = data?.external_domain;
      if (domainDetails) {
        yield put(saveDomainDetails(domainDetails));
      }
      actions.callback?.(true, data);
    } else {
      yield put(saveSubscriptionDetails({ status: false, subscription_data: {} }));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    !actions?.data?.hideErrorMessage && ToastNotifyError("Unable to fetch subscription details");
  }
}

function* setTheme(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/premium/storeTheme`,
      data: appendCreditUsedKey({ ...actions.data, theme_category: 1 }),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPremiumPurchaseType(PREMIUM_PURCHASE_TYPE_CONSTANTS.THEME);
      if (response && response?.data?.data?.is_payable) {
        setPrePaymentDataToSessionStorage(JSON.stringify(response?.data?.data?.payment_details));
        yield put(savePrePaymentData(response?.data?.data?.payment_details));
        actions.callback && actions.callback(true);
      } else {
        actions.callback && actions.callback(false, response?.data);
      }
    }
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Custom Theme is applied, can't set this theme");
  }
}

function* deactivateStoreTheme(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/themes/deactivateStoreTheme`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
  } catch (error) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to deactivate theme !");
  }
}

function* searchDisplayTags(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/themes/getSearchTags?search_text=${actions.data}`,
    });
    if (response && response.status) {
      yield put(saveSearchTags(response.data.tags));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getAllPresetColors() {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/themes/getAllPresetColors`,
    });
    if (response && response.status) {
      yield put(saveAllPresetColors(response.data.color_palettes));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* setStoreThemeColorPalette(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/themes/setStoreThemeColorPalette`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback();
    }
    yield put(setPageLoaderVisibility(false));
    ToastNotifySuccess("Theme color set successfully");
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to set theme color");
  }
}

function* uploadMediaToS3(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/media/uploadMediaToS3`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      actions && actions.callback(parsedData);
    } else {
      actions.callback(false);
      ToastNotifyError("Unable to upload images!");
    }
  } catch (err) {
    actions.callback(false);
    ToastNotifyError("Unable to upload images!");
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* uploadImageLinks(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/premium/addStoreThemeBanner`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Images uploaded!");
    } else {
      ToastNotifyError("Unable to upload images!");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    ToastNotifyError("Something went bad, unable to upload images!");
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* resetImageLink(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/themes/setStoreThemeBanner`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Image uploaded!");
    } else {
      ToastNotifyError("Unable to upload images!");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    ToastNotifyError("Something went bad, unable to upload images!");
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* reOrderStoreBanners(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}/dotk/vm1/premium/reorderStoreThemeBanners`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback();
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Changes saved!");
    } else {
      // ToastNotifyError("Unable to upload images!");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    ToastNotifyError("Something went bad, please try again!");
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* removeStoreBanner(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "DELETE",
      url: `${config.base_url}/dotk/vm1/premium/deleteStoreThemeBanner/${actions.data}`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback(response);
      yield put(setPageLoaderVisibility(false));
    } else {
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* requestPremiumCallback(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${REQUEST_PREMIUM_CALLBACK}`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback(response);
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("We have succesfully received your request. Our executive will call you shortly...");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError("Facing some internal issue. Please try again after some time.");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Facing some internal issue. Please try again after some time.");
    history.goBack();
  }
}

function* getCfeCtaConfigs(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: getCfeCtaConfigsApi(actions.data.themeClass),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      // actions && actions.callback(response);
      yield put(saveCfeCtaConfigs(response.data.data));
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* updateCfeCtaConfigs(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: updateCfeCtaConfigsApi(),
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions.callback?.(response);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    history.goBack();
  }
}

function* updateNameAndLogoVisibility(actions) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}/dotk/vm1/premium/storePremiumFlag`,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions.callback(true);
    } else {
      ToastNotifyError(response?.data?.message || "Something went wrong!");
      actions.callback(false);
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message || "Unknown error!");
    actions.callback(false);
  }
}

export default function* root() {
  yield takeEvery(themeTypes.FETCH_DISPLAY_TAGS, fetchDisplayTags);
  yield takeEvery(themeTypes.FETCH_DISPLAY_THEMES, fetchDisplayThemes);
  yield takeEvery(themeTypes.GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails);
  yield takeEvery(themeTypes.SET_THEME, setTheme);
  yield takeEvery(themeTypes.DEACTIVATE_STORE_THEME, deactivateStoreTheme);
  yield takeEvery(themeTypes.SEARCH_DISPLAY_TAGS, searchDisplayTags);
  yield takeEvery(themeTypes.GET_ALL_PRESET_COLORS, getAllPresetColors);
  yield takeEvery(themeTypes.SET_STORE_THEME_COLOR_PALETTE, setStoreThemeColorPalette);
  yield takeEvery(themeTypes.UPLOAD_IMAGE_TO_S3, uploadMediaToS3);
  yield takeEvery(themeTypes.UPLOAD_IMAGE_LINKS, uploadImageLinks);
  yield takeEvery(themeTypes.RESET_IMAGE_LINK, resetImageLink);
  yield takeEvery(themeTypes.REORDER_STORE_THEME_BANNER, reOrderStoreBanners);
  yield takeEvery(themeTypes.REMOVE_STORE_THEME_BANNER, removeStoreBanner);
  yield takeEvery(themeTypes.REQUEST_PREMIUM_CALLBACK_ACTION, requestPremiumCallback);
  yield takeEvery(themeTypes.GET_CFE_CTA_CONFIGS, getCfeCtaConfigs);
  yield takeEvery(themeTypes.UPDATE_CFE_CTA_CONFIGS, updateCfeCtaConfigs);
  yield takeEvery(themeTypes.UPDATE_LOGO_AND_NAME_VISIBILITY, updateNameAndLogoVisibility);
}
