import React, { useCallback, useContext, useEffect, useState } from "react";
import { CouponsVoucherContextCreator } from "../Contexts/couponsVoucherContext";
import {
  FormErrorObjType,
  ICouponSteps,
  ICouponsVouchersData,
  ICouponsVouchersHook,
  ICouponsVoucherUpdateRes,
  IPartialCouponsVouchersData,
  ISnackbarMessage,
} from "../Types/couponsVouchersTypes";
import {
  COUPON_TYPES,
  COUPONS_STATUSES,
  COUPONS_SUB_TYPE,
  COUPONS_VOUCHERS_DEFAULT_DATA,
  COUPONS_VOUCHERS_FORM_MODE,
  INPUT_TYPES,
  SNACKBAR_TYPES,
  TIME_FORMAT,
} from "@/Constants/couponsVouchersConstants";
import { createPromo } from "@/Redux/Actions";
import { parseInt } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/Redux/Reducers";
import {
  cvDataTranslator,
  getCouponTypeByProductAdded,
  getDefaultCouponCreationData,
  scrollToInvalidSection,
} from "@/Utils/CouponsVouchersUtils";
import validateCouponsVouchersData, { validateSteps } from "@/Utils/CouponsVouchersDataValidators";
import moment from "moment";

export function useCouponsVouchersForContext(): ICouponsVouchersHook {
  const [formMode, setFormMode] = useState<COUPONS_VOUCHERS_FORM_MODE>(COUPONS_VOUCHERS_FORM_MODE.NONE);
  const [formType, setFormType] = useState<COUPON_TYPES>(COUPON_TYPES.UNSET);
  const [couponsVouchersData, setCouponsVouchersData] = useState<ICouponsVouchersData>(COUPONS_VOUCHERS_DEFAULT_DATA);
  const [couponSteps, setCouponSteps] = useState<ICouponSteps>({
    step1: false,
    step2: false,
    step3: false,
    message: "",
    formErrorObj: {},
  });
  const [formErrorView, setFormErrorView] = useState<FormErrorObjType>({});
  const [snackBarMessage, setSnackBarMessage] = useState<ISnackbarMessage>({
    type: SNACKBAR_TYPES.NONE,
    message: "",
  });
  const isLocked = formMode === COUPONS_VOUCHERS_FORM_MODE.VIEW;
  const isSaveAsDraftVisible =
    formMode === COUPONS_VOUCHERS_FORM_MODE.CREATE || couponsVouchersData.status === COUPONS_STATUSES.DRAFT;

  const { storeId, couponDetails } = useSelector((state: RootState) => ({
    storeId: state.storeReducer.store.store_id,
    couponDetails: state.couponsReducer.couponDetails,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    setCouponSteps(validateSteps(couponsVouchersData, formType));
  }, [couponsVouchersData, formType]);

  useEffect(() => {
    setFormErrorView({});
  }, [formMode]);

  useEffect(() => {
    if (Object.values(couponDetails || {}).length > 0) {
      const type = couponDetails?.type;
      if (type === COUPON_TYPES.ON_CART_AMOUNT || type === COUPON_TYPES.X_ON_Y) {
        changeFormType(type, false);
      } else {
        changeFormType(COUPON_TYPES.FLAT_OFF, false);
      }
    }
  }, [couponDetails]);

  const changeFormMode = (mode: COUPONS_VOUCHERS_FORM_MODE) => {
    setFormMode(mode);
  };

  const showSnackbarMessage = (message: string, type: number = SNACKBAR_TYPES.INFO) => {
    setSnackBarMessage({
      type: type,
      message: message,
    });
  };

  const changeFormType = useCallback(
    (type: COUPON_TYPES, isCreate = true) => {
      setFormType(type);
      if (isCreate) {
        setCouponsVouchersData(getDefaultCouponCreationData());
      } else {
        const dataToPopulateForm = cvDataTranslator(couponDetails, { fromBEToFE: true });
        setCouponsVouchersData(dataToPopulateForm);
      }
    },
    [couponDetails],
  );

  const obSubmit = useCallback(
    (saveStatus: COUPONS_STATUSES) => {
      const dataToSubmit: ICouponsVouchersData = cvDataTranslator(couponsVouchersData, { fromBEToFE: false });
      dataToSubmit.store_id = parseInt(storeId);
      if (formType === COUPON_TYPES.FLAT_OFF) {
        // FE has to send the type of product added from COUPON_TYPES enum as coupon type when COUPON_TYPES.FLAT_OFF is selected
        dataToSubmit.type = getCouponTypeByProductAdded(dataToSubmit.promo_applicability);
      } else {
        dataToSubmit.type = formType;
      }
      dataToSubmit.status = saveStatus;

      if (saveStatus === COUPONS_STATUSES.DRAFT) {
        // as it only required type, status and coupon code to save in draft
        if (!dataToSubmit.code) {
          setSnackBarMessage({
            type: SNACKBAR_TYPES.ERROR,
            message: "Enter coupon code!",
          });
          return;
        }
      } else if (saveStatus === COUPONS_STATUSES.DELETED) {
        if (!dataToSubmit.id) {
          setFormType(COUPON_TYPES.UNSET);
          setFormMode(COUPONS_VOUCHERS_FORM_MODE.NONE);
          return;
        }
      } else if (Object.keys(couponSteps.formErrorObj || {}).length > 0) {
        setFormErrorView(couponSteps.formErrorObj);
        setSnackBarMessage({
          type: SNACKBAR_TYPES.ERROR,
          message: "Please fill the required fields.",
        });
        scrollToInvalidSection(couponSteps);
        return;
      }

      dispatch(
        createPromo(dataToSubmit, formMode === COUPONS_VOUCHERS_FORM_MODE.CREATE, (data) => {
          let successMessage: any = "Done";
          let snackbarType: SNACKBAR_TYPES = SNACKBAR_TYPES.INFO;
          if (saveStatus === COUPONS_STATUSES.DELETED) {
            successMessage = 
              <div>
                You deleted coupon <b>{dataToSubmit.code}</b>
              </div>
            ;
            snackbarType = SNACKBAR_TYPES.INFO;
          } else if (saveStatus === COUPONS_STATUSES.DRAFT) {
            successMessage = 
              <div>
                Coupon <b>{dataToSubmit.code}</b> saved as draft.
              </div>
            ;
          } else if (saveStatus === COUPONS_STATUSES.ACTIVE) {
            const isDateAfterToday = moment(dataToSubmit.start_date).isAfter(moment());
            const isDateSameAsToday = moment(dataToSubmit.start_date).isSame(moment());
            const isTimeAfterCurrent = moment(dataToSubmit.start_time, TIME_FORMAT).isAfter(moment());
            if (isDateAfterToday || isDateSameAsToday && isTimeAfterCurrent) {
              successMessage = 
                <div>
                  Coupon <b>{dataToSubmit.code}</b> created successfully.
                </div>
              ;
            } else {
              successMessage = 
                <div>
                  Coupon <b>{dataToSubmit.code}</b> is activated.
                </div>
              ;
            }
          } else if (saveStatus === COUPONS_STATUSES.INACTIVE) {
            successMessage = 
              <div>
                Coupon <b>{dataToSubmit.code}</b> is inactive.
              </div>
            ;
          } else if (formMode === COUPONS_VOUCHERS_FORM_MODE.CREATE) {
            successMessage = 
              <div>
                Coupon <b>{dataToSubmit.code}</b> created successfully.
              </div>
            ;
          } else if (formMode === COUPONS_VOUCHERS_FORM_MODE.UPDATE) {
            successMessage = "Changes saved successfully.";
          }

          if (data?.status) {
            setSnackBarMessage({
              type: snackbarType,
              message: successMessage,
            });
            resetForm();
          } else {
            setSnackBarMessage({
              type: SNACKBAR_TYPES.ERROR,
              message: <div>Something went wrong</div>,
            });
          }
        }),
      );
    },
    [couponsVouchersData, storeId, formType, couponSteps, formMode],
  );

  const resetForm = () => {
    changeFormType(COUPON_TYPES.UNSET);
    changeFormMode(COUPONS_VOUCHERS_FORM_MODE.NONE);
  };

  const updateCouponsVouchersData = (
    type: INPUT_TYPES,
    data: IPartialCouponsVouchersData,
  ): ICouponsVoucherUpdateRes => {
    if (formErrorView && formErrorView[type]) {
      const errorObjCopy = { ...formErrorView };
      delete errorObjCopy[type];
      setFormErrorView(errorObjCopy);
    }

    const validation = validateCouponsVouchersData(type, couponsVouchersData, data);
    if (validation.error) {
      return validation;
    }
    setCouponsVouchersData((prevData) => ({ ...prevData, ...data }));
    return validation;
  };

  return {
    formMode: formMode,
    setFormMode: changeFormMode,
    formType,
    changeFormType,
    couponsVouchersData: couponsVouchersData,
    updateCouponsVouchersData,
    obSubmit,
    couponSteps,
    snackBarMessage,
    formErrorView,
    showSnackbarMessage,
    isSaveAsDraftVisible,
    isLocked,
    isExitGatingCoupon: couponDetails.sub_type === COUPONS_SUB_TYPE.EXIT_GATING,
  };
}

function useCouponsVouchers() {
  const data = useContext<ICouponsVouchersHook>(CouponsVoucherContextCreator);
  if (data === undefined) {
    throw new Error("useCouponsVouchers must be used within a CouponsVoucherContext");
  }

  return data;
}

export default useCouponsVouchers;
