import React, {createContext} from 'react';
import { ICouponsVouchersHook } from '../Types/couponsVouchersTypes';
import { useCouponsVouchersForContext } from '../Hooks/useCouponsVouchers';

export const CouponsVoucherContextCreator = createContext<ICouponsVouchersHook>(
  {} as ICouponsVouchersHook,
);

export const CouponsVoucherContext = ({children}) => {
  const data = useCouponsVouchersForContext();

  return (
    <CouponsVoucherContextCreator.Provider value={data}>
      {children}
    </CouponsVoucherContextCreator.Provider>
  );
};
