import React from "react";

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#111C36"
        d="M16.5 4.866l-1.295-1.295-5.134 5.134-5.133-5.134-1.295 1.295L8.776 10l-5.133 5.134 1.295 1.295 5.133-5.134 5.134 5.134 1.295-1.295L11.366 10 16.5 4.866z"
      ></path>
    </svg>
  );
}

export default CrossIcon;
