import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../CommonStyles/reset.scss";
import history from "../Utils/history";
import AppRouter from "../routes";
import LocalStorageHelper from "../Utils/LocalStorageHelper";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../theme";
const PremiumSearchModal = React.lazy(() => import("@/Components/PremiumSearchModal"));
const VariantSelectedListModal = React.lazy(() => import("@/Components/VariantSelectedListModal"));
const AddVariantListModal = React.lazy(() => import("@/Components/AddVariantListModal"));
const BillContactModal = React.lazy(() => import("@/Components/BillContactModal"));
const CreateBillSuccessModal = React.lazy(() => import("@/Components/CreateBillSuccessModal"));
const RequestCallbackSuccessPopup = React.lazy(() => import("@/Components/RequestCallbackSuccessPopup"));
const DomainRenewalModal = React.lazy(() => import("@/Components/DomainRenewalModal"));
const WorkspaceExpiryModal = React.lazy(() => import("@/Components/WorkspaceExpiryModal"));
const SubscriptionExpiryModal = React.lazy(() => import("@/Components/SubscriptionExpiryModal"));
const BulkUploadSuccessModal = React.lazy(() => import("../Components/BulkUpload/bulkUploadSuccessModal"));
const UploadHsnCatalogSheetModal = React.lazy(
  () => import("../Components/BulkUpload/UploadHsnCatalogSheetModal/index"),
);
const StaffInvitationModal = React.lazy(() => import("../Components/StaffInvitationModal"));
import { RootState } from "../Redux/Reducers";
import {
  checkStaffInvite,
  updateUserInvitation,
  saveStoreInfo,
  authenticateUser,
  fetchRestrictionConfig,
} from "../Redux/Actions";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "@dotpe/additional-pages/dist/styles/index.scss";
import { BUY_SUBSCRIPTION } from "../Constants/routesConstant";
import { ORDER_DATE_RANGE, SETTLEMENT_DATE_RANGE } from "../Constants/localStorageKeys";
import { getAuthToken } from "../Utils/authToken";
import { getLoginUrl, clevertapEventPush } from "../Utils/common";
import SubscriptionRequestCallbackModal from "../CommonElements/Modal/SubscriptionRequestCallbackModal/SubscriptionRequestCallbackModal";
import { useCheckLoginStatus } from "./hooks/useCheckLoginStatus";
import { useRenderFBAds } from "./hooks/useRenderFBAds";
import { useMobileSpecificMethods } from "./hooks/useMobileSpecificMethods";
import { useUploadTimers } from "./hooks/useUploadTimers";
import { useOpenRouteMethods } from "./hooks/useOpenRouteMethods";
import { useCheckEmailVerification } from "./hooks/useCheckEmailVerification";
import useRestrictionsConfig from "@/Hooks/useRestrictionsConfig";
import PageLoader from "../Components/PageLoader";
import { themeV5 } from "./../theme";
import RestrictionsModal from "@/Components/RestrictionsModal";

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const [showStaffInvitationModal, setShowStaffInvitationModal] = useState(false);
  const { staffInvitationData, storeData } = useSelector((state: RootState) => ({
    staffInvitationData: state.staffManagementReducer.staffInvitationData,
    storeData: state.storeReducer.store,
  }));
  const { homeScreenModalConfig } = useRestrictionsConfig();
  const isWebview = window.location.pathname.startsWith("/webview");

  useCheckLoginStatus();
  useOpenRouteMethods();
  useRenderFBAds();
  useMobileSpecificMethods();
  useUploadTimers();
  useCheckEmailVerification();

  useEffect(() => {
    if (isWebview) {
      return;
    }
    storeData?.store_id && dispatch(fetchRestrictionConfig({ storeId: storeData?.store_id }));
  }, [storeData?.store_id]);

  useEffect(() => {
    if (isWebview) {
      return;
    }

    if (window.location.pathname !== BUY_SUBSCRIPTION) {
      const callback = (response: any) => {
        if (response?.is_invitation_available) {
          setShowStaffInvitationModal(true);
        } else if (getAuthToken()) {
          dispatch(checkStaffInvite(callback));
          clevertapEventPush("App_Launched");
          if (
            !["/domain-completion"].includes(window.location.pathname) &&
            (history.location.pathname.includes("/login") || history.location.pathname === "/")
          ) {
            history.push(`/orders${getLoginUrl()}`);
          }
        } else {
          if (
            ![BUY_SUBSCRIPTION, "/domain-sale", "/domain-completion", "/domain-open-search", "/payment"].includes(
              window.location.pathname,
            )
          ) {
            // add window.location.search to accomodate search params for create store in onboarding page
            // if window.location.search is "" then nothing happens
            history.push(`/${window?.location?.search || ""}`);
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname !== BUY_SUBSCRIPTION &&
      staffInvitationData?.is_invitation_available &&
      !showStaffInvitationModal &&
      !(history.location.pathname.includes("/login") || history.location.pathname === "/")
    ) {
      setShowStaffInvitationModal(true);
    }
  }, [staffInvitationData]);

  const onInvitationAction = (actionType: any) => {
    if (!actionType) {
      setTimeout(() => {
        LocalStorageHelper.delete("auth_token");
        LocalStorageHelper.delete("state");
        LocalStorageHelper.delete(SETTLEMENT_DATE_RANGE);
        LocalStorageHelper.delete(ORDER_DATE_RANGE);
        history.push(getLoginUrl());
        location.reload();
      }, 1000);
    } else {
      const dataToPost = {
        store_id: staffInvitationData?.staff_invitation?.invited_store_id,
        user_id: storeData?.user_id,
        status: actionType,
      };
      const callback = (response: any) => {
        setShowStaffInvitationModal(false);
        // if accepted
        if (actionType === 1) {
          dispatch(saveStoreInfo(response));
          if (window.location.pathname.includes("/login")) {
            history.push("/orders");
          }
          getAuthToken() && dispatch(authenticateUser(getAuthToken(), history));
        } else {
          if (getAuthToken()) {
            if (window.location.pathname.includes("/login")) {
              history.push("/orders");
            }
          } else {
            if (window.location.pathname !== BUY_SUBSCRIPTION) {
              history.push(getLoginUrl());
            }
          }
        }
      };
      dispatch(updateUserInvitation(dataToPost, callback));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderV5 theme={themeV5}>
        <CssBaseline />
        <AppRouter />
        <Suspense fallback={<PageLoader show={true} />}>
          <PremiumSearchModal />
          <AddVariantListModal />
          <VariantSelectedListModal />
          <BillContactModal />
          <CreateBillSuccessModal />
          <BulkUploadSuccessModal />
          <UploadHsnCatalogSheetModal />
          {showStaffInvitationModal && 
            <StaffInvitationModal
              show={showStaffInvitationModal}
              onClose={onInvitationAction}
              staffData={staffInvitationData?.staff_invitation}
            />
          }
          <WorkspaceExpiryModal />
          <DomainRenewalModal />
          <SubscriptionExpiryModal />
          <RestrictionsModal {...homeScreenModalConfig} />
          <RequestCallbackSuccessPopup inRootTree={true} />
          <SubscriptionRequestCallbackModal />
        </Suspense>
      </ThemeProviderV5>
    </ThemeProvider>
  );
};

export default App;
