import { ICouponsVouchersData } from "@/Types/couponsVouchersTypes";
import moment from "moment";

export const stringSet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const alphabetsStringSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const COUPON_CODE_LENGTH = 20;
export const AUTO_GENERATED_COUPON_CODE_LENGTH = 10;
export const MIN_COUPON_CODE_LENGTH = 4;
export const DEFAULT_CATALOG_IMAGE = "https://cdn.dotpe.in/longtail/collection/compressed/5498/wOHMq7Dc.webp";
export const ALPHANUMERIC_STRING_REGEX = /^[0-9a-zA-Z]+$/;
export const TEMPLATE_REPLACE_STRING = "XXXXXX";

export const disabledContainerClass = "tw-opacity-50 tw-pointer-events-none";

export type STRING_NUMBER_TYPE = `${number}` | "" | number;

export const DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";
export const TIME_FORMAT = "HH:mm:ss";

export const INIT_NUMBER_FOR_APPLICABILITY = 5;

export const FORM_SECTION_IDS = {
  DISCOUNT_DETAILS: "DISCOUNT_DETAILS_gibberish",
  CUSTOMER_SETTINGS: "CUSTOMER_SETTINGS_gibberish",
  COUPONS_SETTINGS: "COUPONS_SETTINGS_gibberish",
};

export const enum COUPON_TYPES {
  UNSET = 0,
  X_ON_Y = 6,
  ON_CART_AMOUNT = 1,

  // FLAT_OFF's value is not defined on backend
  FLAT_OFF = 99,
  // for the case when FLAT_OFF is selected on FE,
  // then any of the below 4 values defined below will be sent as COUPON_TYPE to backend
  PRODUCT = 2,
  TAG = 3,
  CATEGORY = 4,
  COLLECTION = 5,
}

export enum COUPONS_VOUCHERS_FORM_MODE {
  NONE = 0,
  CREATE = 1,
  UPDATE = 2,
  VIEW = 3,
}

export enum COUPONS_STATUSES {
  ALL = 0,
  ACTIVE = 1,
  DELETED = 2,
  HIDDEN = 3,
  DRAFT = 4,
  INACTIVE = 5,
  EXPIRED = 6,
  SCHEDULED = 7,
}

// export enum COUPONS_TYPE {
//   GENERAL = 1,
//   PRODUCT = 2,
//   TAG = 3,
//   CATEGORY = 4,
//   COLLECTION = 5,
//   BUYXGETY = 6,
// }

export enum COUPONS_DISCOUNT_TYPE {
  PERCENTAGE = 1,
  FLAT = 2,
  FREEBIE = 3,
}

export enum COUPONS_ORDER_TYPE {
  ORDER_TYPE_ALL = 1,
  ORDER_TYPE_PREPAID = 2,
}

export enum COUPONS_CUSTOMER_GROUP_TYPES {
  ALL = 1,
  NEW = 2,
  REPEAT = 3,
  SUBSCRIBERS = 4,
  ACTIVE = 5,
  CUSTOM_EXCEL = 6,
  CUSTOM_FILTER = 7,
}

export enum COUPONS_APPLICABILITY_TYPES {
  TYPE_PRODUCT = 1,
  TYPE_TAG = 2,
  TYPE_CATEGORY = 3,
  TYPE_COLLECTION = 4,
}

export enum COUPONS_BROWSE_TYPES {
  TYPE_PRODUCT = 0,
  TYPE_TAG = 1,
  TYPE_CATEGORY = 2,
  TYPE_COLLECTION = 3,
}

export enum COUPONS_SORT_BY {
  CREATED_AT = 1,
  MAX_DISCOUNT = 2,
}

export enum COUPONS_SORT_TYPE {
  SORT_TYPE_ASC = 0,
  SORT_TYPE_DESC = 1,
}

export enum COUPONS_SUB_TYPE {
  GENERAL = 0,
  EXIT_GATING = 1,
}

export enum COUPONS_VOUCHERS_ITEMS_SELECTER_CALLER {
  APPLIES_ON = 1,
  BUYS_X = 2,
  GETS_Y = 3,
}

export enum COUPONS_VOUCHERS_ITEMS_VALUE_TYPE {
  QUANTITY = 1,
  PRICE = 2,
}

// FE specific values to determine type of input being edited
export enum INPUT_TYPES {
  CODE = 1,
  STATUS = 2,
  TYPE = 3,
  DISCOUNTING_TYPE = 4,
  DISCOUNT = 5,
  MAX_DISCOUNT = 6,
  MIN_ORDER_VALUE = 7,
  MIN_ITEM_QUANTITY = 8,
  REWARD_ITEM_QUANTITY = 9,
  START_DATE = 10,
  END_DATE = 11,
  START_TIME = 12,
  END_TIME = 13,
  ORDER_TYPE = 14,
  MAX_USAGE = 15,
  USE_COUNT = 16,
  REVENUE = 17,
  CUSTOMER_GROUP_LIST = 18,
  PROMO_APPLICABILITY = 19,
  PROMO_REWARD_APPLICABILITY = 20,
  ADDED_AS_HELLO_BAR = 21,
  IS_VISIBLE_ON_WEBSITE = 22,
}

export enum SNACKBAR_TYPES {
  NONE = 0,
  ERROR = 1,
  INFO = 2,
}

// any change to backend object needs to be done on this object and ICouponsVouchersData
export const COUPONS_VOUCHERS_DEFAULT_DATA: ICouponsVouchersData = {
  id: null,
  code: "",
  discounting_type: `${COUPONS_DISCOUNT_TYPE.PERCENTAGE}`,
  discount: "",
  max_discount: "",
  min_order_value: "",
  min_item_quantity: "",
  reward_item_quantity: "",
  start_date: moment().format(DATE_FORMAT),
  end_date: "",
  start_time: moment().format(TIME_FORMAT),
  end_time: "",
  order_type: `${COUPONS_ORDER_TYPE.ORDER_TYPE_PREPAID}`,
  use_count: "",
  revenue: "",
  max_usage: "0",
  added_as_hello_bar: 0,
  is_visible_on_website: 1,
  customer_group_list: [{ customer_group_type: COUPONS_CUSTOMER_GROUP_TYPES.ALL }],
  promo_applicability: [],
  promo_reward_applicability: [],
  updated_date: "",
  updated_time: "",
  sub_type: COUPONS_SUB_TYPE.GENERAL,
};
