import moment from "moment";
import {
  ALPHANUMERIC_STRING_REGEX,
  COUPON_CODE_LENGTH,
  COUPON_TYPES,
  COUPONS_DISCOUNT_TYPE,
  INPUT_TYPES,
  MIN_COUPON_CODE_LENGTH,
  STRING_NUMBER_TYPE,
  TEMPLATE_REPLACE_STRING,
  TIME_FORMAT,
} from "./../Constants/couponsVouchersConstants";
import {
  FormErrorObjType,
  ICouponSteps,
  ICouponsVouchersData,
  ICouponsVoucherUpdateRes,
  IPartialCouponsVouchersData,
} from "./../Types/couponsVouchersTypes";

export default function validateCouponsVouchersData(
  type: INPUT_TYPES,
  completeData: ICouponsVouchersData,
  partialData: IPartialCouponsVouchersData,
): ICouponsVoucherUpdateRes {
  let errorMessage = "";

  switch (type) {
    case INPUT_TYPES.CODE:
      if (partialData?.code?.length > COUPON_CODE_LENGTH) {
        errorMessage = "Coupon code length cannot be greater than " + COUPON_CODE_LENGTH;
      }
      if (!ALPHANUMERIC_STRING_REGEX.test(partialData?.code) && partialData?.code) {
        errorMessage = "Coupon code can only contain alphanumeric characters.";
      }
      break;
    case INPUT_TYPES.DISCOUNT:
      errorMessage = getMessageIfNotANumber(partialData.discount, "Discount should be a number!");
      if (
        !errorMessage &&
        completeData.discounting_type === `${COUPONS_DISCOUNT_TYPE.PERCENTAGE}` &&
        parseFloat(partialData.discount as string) > 99
      ) {
        errorMessage = "Percentage cannot exceed 99%.";
      }
      errorMessage = newMessage(errorMessage, isLessThanZero(partialData.discount, "Discount cannot be negative."));
      errorMessage = newMessage(errorMessage, isFloat(partialData.discount, "Discount must be a whole number."));
      break;
    case INPUT_TYPES.MAX_DISCOUNT:
      errorMessage = getMessageIfNotANumber(partialData.max_discount, "Max discount should be a number!");
      errorMessage = newMessage(errorMessage, isLessThanZero(partialData.max_discount, "Max discount cannot be negative."));
      break;
    case INPUT_TYPES.MIN_ORDER_VALUE:
      errorMessage = getMessageIfNotANumber(partialData.min_order_value, "Value should be a number!");
      errorMessage = newMessage(errorMessage, isLessThanZero(partialData.min_order_value, "Minimum order value cannot be negative."));
      break;
    case INPUT_TYPES.MIN_ITEM_QUANTITY:
      errorMessage = getMessageIfNotANumber(partialData.min_item_quantity, "Quantity should be a number!");
      errorMessage = newMessage(errorMessage, isLessThanZero(partialData.min_item_quantity, "Minimum item quantity cannot be negative."));
      errorMessage = newMessage(errorMessage, isFloat(partialData.min_item_quantity, "Quantity must be a whole number."));
      break;
    case INPUT_TYPES.REWARD_ITEM_QUANTITY:
      errorMessage = getMessageIfNotANumber(partialData.reward_item_quantity, "Quantity should be a number!");
      errorMessage = newMessage(errorMessage, isLessThanZero(partialData.reward_item_quantity, "Quantity cannot be negative."));
      errorMessage = newMessage(errorMessage, isFloat(partialData.reward_item_quantity, "Quantity must be a whole number."));
      break;
    default:
      break;
  }

  return {
    error: Boolean(errorMessage),
    errorMessage,
  };
}

function getMessageIfNotANumber(value: any, errorMessage = "") {
  if (isNaN(value)) {
    return errorMessage;
  } else {
    return "";
  }
}

export function validateSteps(couponsData: ICouponsVouchersData, formType: COUPON_TYPES): ICouponSteps {
  const steps: ICouponSteps = {
    step1: false,
    step2: false,
    step3: false,
    message: "",
    formErrorObj: {},
  };

  // isValid, message, errorObj
  const {isValid: step1, message: message1, errorObj: errorObj1} = isStep1Valid(couponsData, formType);
  const {isValid: step2, message: message2, errorObj: errorObj2} = isStep2Valid(couponsData);
  const {isValid: step3, message: message3, errorObj: errorObj3} = isStep3Valid(couponsData);

  steps.step1 = step1;
  steps.step2 = step2;
  steps.step3 = step3;
  steps.message = message1 || message2 || message3;
  steps.formErrorObj = {...errorObj1, ...errorObj2, ...errorObj3};

  return steps;
}

function isStep1Valid(couponsData: ICouponsVouchersData, formType: COUPON_TYPES): {isValid: boolean; message: string; errorObj: FormErrorObjType} {
  let isValid = true;
  let message = "";
  const errorObj: FormErrorObjType = {};

  if (!couponsData.code) {
    isValid = false;
    const msg = "Enter coupon code";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.CODE] = msg;
  } else if ((couponsData.code || "").length < MIN_COUPON_CODE_LENGTH) {
    isValid = false;
    const msg = `Coupon code must be between ${MIN_COUPON_CODE_LENGTH} to ${COUPON_CODE_LENGTH} characters.`;
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.CODE] = msg;
  }
  if (!parseFloat(couponsData.discount as string) && couponsData.discounting_type !== `${COUPONS_DISCOUNT_TYPE.FREEBIE}`) {
    isValid = false;
    let msg = "Discount value required.";
    if (couponsData.discounting_type === `${COUPONS_DISCOUNT_TYPE.PERCENTAGE}`) {
      msg = "Enter percentage discount";
    }
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.DISCOUNT] = msg;
  }
  if (formType === COUPON_TYPES.FLAT_OFF) {
    if (couponsData?.promo_applicability?.length <= 0) {
      isValid = false;
      const msg = `Add ${TEMPLATE_REPLACE_STRING} to your discount coupons`;
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.PROMO_APPLICABILITY] = msg;
    }
  } else if (formType === COUPON_TYPES.X_ON_Y) {
    if (couponsData?.promo_applicability?.length <= 0) {
      isValid = false;
      const msg = `Add ${TEMPLATE_REPLACE_STRING} to your discount coupons (for X)`;
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.PROMO_APPLICABILITY] = msg;
    }
    if (couponsData?.promo_reward_applicability?.length <= 0) {
      isValid = false;
      const msg = `Add ${TEMPLATE_REPLACE_STRING} to your discount coupons (for Y)`;
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.PROMO_REWARD_APPLICABILITY] = msg;
    }
    if (!parseFloat(couponsData?.min_order_value as string) && !parseFloat(couponsData?.min_item_quantity as string)) {
      isValid = false;
      message = newMessage(message, "Select min order quantity/value");
      errorObj[INPUT_TYPES.MIN_ITEM_QUANTITY] = "Select min order quantity";
      errorObj[INPUT_TYPES.MIN_ORDER_VALUE] = "Select min order value";
    }
    if (!parseInt(couponsData?.reward_item_quantity as string)) {
      isValid = false;
      const msg = "Select min order quantity for reward";
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.REWARD_ITEM_QUANTITY] = msg;
    }
  } else if (formType === COUPON_TYPES.ON_CART_AMOUNT) {
    if (!couponsData?.min_order_value || !parseFloat(couponsData?.min_order_value as string)) {
      isValid = false;
      const msg = "Enter minimum cart amount";
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.MIN_ORDER_VALUE] = msg;
    }
  } else {
    isValid = false;
    message = newMessage(message, "Coupon type not matched");
  }

  return {isValid, message, errorObj};
}

function isStep2Valid(couponsData: ICouponsVouchersData): {isValid: boolean; message: string; errorObj: FormErrorObjType} {
  let isValid = true;
  let message = "";
  const errorObj: FormErrorObjType = {};

  if (couponsData?.customer_group_list?.length === 0) {
    isValid = false;
    message = 'Please select a valid customer group.';
    errorObj[INPUT_TYPES.CUSTOMER_GROUP_LIST] = message;
  }
  return {isValid, message, errorObj};
}

function isStep3Valid(couponsData: ICouponsVouchersData): {isValid: boolean; message: string; errorObj: FormErrorObjType} {
  let isValid = true;
  let message = "";
  const errorObj: FormErrorObjType = {};

  if (!moment(couponsData.start_date).isValid()) {
    isValid = false;
    const msg = "Invalid start date";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.START_DATE] = msg;
  } if (!couponsData.id && moment(couponsData.start_date).isBefore(moment(), "date")) {
    // id is not present when creating coupon
    isValid = false;
    const msg = "Start date cannot be in the past.";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.START_DATE] = msg;
  } else if (!moment(couponsData.start_time, TIME_FORMAT, true).isValid()) {
    isValid = false;
    const msg = "Invalid start time";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.START_TIME] = msg;
  } else if (couponsData.end_time && !moment(couponsData.end_time, TIME_FORMAT, true).isValid()) {
    isValid = false;
    const msg = "Invalid end time";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.END_TIME] = msg;
  } else if (couponsData.end_time && couponsData.end_date && moment(couponsData.start_date).isSame(couponsData.end_date)) {
    if (moment(couponsData.end_time, TIME_FORMAT).isSameOrBefore(moment(couponsData.start_time, TIME_FORMAT))) {
      isValid = false;
      const msg = "End time cannot be before start time on same day.";
      message = newMessage(message, msg);
      errorObj[INPUT_TYPES.END_TIME] = msg;
    }
  } else if (couponsData.end_date && !moment(couponsData.end_date).isValid() || (!couponsData.id && moment(couponsData.end_date).isBefore(moment(), "date"))) {
    // id is not present when creating coupon
    isValid = false;
    const msg = "Invalid end date";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.END_DATE] = msg;
  } else if (couponsData.start_date && couponsData.end_date && !moment(couponsData.start_date).isSameOrBefore(couponsData.end_date)) {
    isValid = false;
    const msg = "End date cannot be earlier than start date.";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.END_DATE] = msg;
  } else if (couponsData.end_date && moment(couponsData.end_date).isBefore(moment(), "date")) {
    isValid = false;
    const msg = "End date cannot be before current date";
    message = newMessage(message, msg);
    errorObj[INPUT_TYPES.END_DATE] = msg;
  }
  return {isValid, message, errorObj};
}

function isLessThanZero(value: STRING_NUMBER_TYPE, newMessage: string): string {
  return Number(value as string) < 0 ? newMessage : "";
}

function isFloat(n, newMessage){
  return Number(n) % 1 !== 0 ? newMessage : "";
}

function newMessage(oldMessage: string, newMessage: string) {
  return oldMessage || newMessage;
}
