import React, { useEffect, useState } from "react";
import { ModalConfigType } from "@/Types/userRestrictions";
import { useSelector } from "react-redux";
import { RootState } from "@/Redux/Reducers";
import { RESTRICTIONS_STATUS } from "@/Constants/userRestrictions";
import LocalStorageHelper from "@/Utils/LocalStorageHelper";
import { HOME_PAGE_USER_RESTRICTION_SEEN } from "@/Constants/commons";

function useRestrictionsConfig() {
  const [homeScreenModalConfig, setHomeScreenModalConfig] = useState<ModalConfigType>({ isVisible: false });
  const [featureRestrictionModal, setFeatureRestrictionModal] = useState<ModalConfigType>({ isVisible: false });
  const { restrictionsConfig } = useSelector((state: RootState) => ({
    restrictionsConfig: state.commonReducer.restrictionsConfig,
  }));

  const blockedFeatures = restrictionsConfig?.store_restriction_config_list;

  useEffect(() => {
    if (!blockedFeatures?.length) {
      return;
    }

    const homePageModalObject = blockedFeatures?.[0];

    const val = LocalStorageHelper.get(HOME_PAGE_USER_RESTRICTION_SEEN);
    if (homePageModalObject?.status === RESTRICTIONS_STATUS.inactive && !val) {
      setHomeScreenModalConfig(generateModalObject(homePageModalObject?.static_text));
      LocalStorageHelper.add(HOME_PAGE_USER_RESTRICTION_SEEN, true);
    }
  }, [restrictionsConfig]);

  const generateModalObject = (staticText): ModalConfigType => {
    return {
      isVisible: true,
      body: staticText?.body,
      heading: staticText?.heading,
      onClose: closeModal,
      cta: {
        text: staticText?.cta?.text,
        backgroundColor: staticText?.bg_color,
        textColor: staticText?.text_color,
      },
    };
  };

  const closeModal = () => {
    setHomeScreenModalConfig({ isVisible: false });
  };

  const onFeatureModalClose = () => {
    setFeatureRestrictionModal({ isVisible: false });
  };

  const getRestrictionByName = (featureName): ModalConfigType | undefined => {
    const restrictionObject = blockedFeatures?.find((feature) => featureName === feature?.feature_name);
    if (restrictionObject?.status === RESTRICTIONS_STATUS.inactive) {
      const modalRestrictionObj = generateModalObject(restrictionObject?.static_text);
      setFeatureRestrictionModal(modalRestrictionObj);
      return modalRestrictionObj;
    }
    return undefined;
  };

  return {
    homeScreenModalConfig,
    getRestrictionByName,
    featureRestrictionModal,
    setFeatureRestrictionModal,
    onFeatureModalClose,
  };
}

export default useRestrictionsConfig;
