import React from "react";
import { ModalConfigType } from "@/Types/userRestrictions";
import GenericDialog from "../ds-design-system/Components/Dialog/genericDialog";
import Button from "@/Components/ds-design-system/Components/Button";

function RestrictionsModal(props: ModalConfigType) {
  const { isVisible, body, cta, heading, onClose } = props;

  return (
    <GenericDialog open={isVisible} handleClose={onClose} showCloseButtom={false} heading={""}>
      <div className="tw-px-5 tw-py-6">
        <h1 className="tw-my-0 tw-text-[20px] tw-font-bold">{heading}</h1>
        <div className="tw-mt-4">
          {body?.map((row, idx) => {
            return (
              <div key={idx} className="tw-mb-3 tw-text-[14px] tw-font-normal tw-text-blue-v1 tw-opacity-60">
                {row}
              </div>
            );
          })}
        </div>
        <div className="tw-mt-6">
          <Button
            className={`!tw-px-5 !tw-py-2 !tw-text-sm !tw-font-semibold`}
            customVariant="primary"
            onClick={props.onClose}
          >
            {cta?.text}
          </Button>
        </div>
      </div>
    </GenericDialog>
  );
}

export default RestrictionsModal;
