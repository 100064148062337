import { setPageLoaderVisibility, saveUserAccountInfo } from "./../Actions";
import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import apiCall from "../../Services/api";
import {
  COMMON_API,
  DOMAIN_API,
  OUT_OF_STOCK_API,
  PUSH_EVENT_LOG_API,
  SETTING_PAGE_SEARCH_API,
  STORE_POLICY_API,
  USER_INFO_API_URL,
} from "../../Services/apiUrls";
import config from "../../Config";
import {
  IS_PREMIUM_SET,
  GET_ACCOUNT_INFO,
  PUSH_EVENT_LOG,
  GET_ALL_POLICY_PAGES,
  UPDATE_POLICY_PAGES,
  OUT_OF_STOCK,
  UPLOAD_COMPRESSED_IMAGE_TO_S3,
  SEND_DOMAIN_CALLBACK_REQUEST,
  GET_SEARCH_SETTING_DATA,
  GET_APP_CONSTANTS,
} from "../ActionTypes";
import { parseToJson } from "../../Utils/common";
import { redirectFromPaymentPage } from "../../Utils/_helper";
import store from "../Store";
import { saveAppConstants, saveRestrictionConfig, saveSearchSettingData, saveStorePolicies } from "../Actions/common";
import { FETCH_RESTRICTIONS_CONFIG } from "../ActionTypes/common";

function* isPremiumSet(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));

    // Creating this for later redirection
    const redirectType = actions.data.type;

    /**
     * Assuming we have only 2 type or
     * subscription 1 for theme and 2
     * for domains
     * */
    if (actions.data.type === 3) {
      actions.data.type = 2;
    }

    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/premium/isSet?type_id=${actions.data.type_id}&type=${actions.data.type}&text=${actions.data.text}`,
    });
    if (response && response?.data?.status) {
      redirectFromPaymentPage(redirectType);
    } else {
      ToastNotifyError(`${response?.data?.message}`);
    }
  } catch (error: any) {
    yield put(setPageLoaderVisibility(false));
    if (error?.data?.error_type !== "invalid_input_error") {
      ToastNotifyError(`Unable to verify the payment yet ! please wait for 2-4 hours`);
    }
  }
}

/**
 * This function will fetch the user account info
 * @param actions
 */
function* getUserAccountInfo(actions: any) {
  try {
    // yield put(setPageLoaderVisibility(true));
    const userAccountInfo = yield apiCall({
      method: "GET",
      url: `${config.base_url}${USER_INFO_API_URL}`,
    });
    if (userAccountInfo?.data?.status) {
      const parsedData = parseToJson(userAccountInfo.data.data);
      yield put(saveUserAccountInfo(parsedData));
    }
  } catch (err: any) {
    // yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch pending orders");
  }
}
// api/dotanalytics/push/pushEventLog

function* pushEventLogFunc(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${PUSH_EVENT_LOG_API}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      // @ts-ignore
    }
  } catch (err) {
    // @ts-ignore
  }
}

function* getAllPolicyPages(actions: any): any {
  try {
    const storeId = store.getState().storeReducer.store.store_id;
    if (storeId) {
      const response = yield apiCall({
        method: "GET",
        url: `${config.base_url}${STORE_POLICY_API.getAllPolicyPages(storeId)}`,
      });
      if (response?.data?.status) {
        const parsedData = parseToJson(response.data.data);
        yield put(saveStorePolicies(parsedData));
        actions?.callback?.(!!parsedData?.length);
      }
    }
  } catch (err: any) {
    err?.callback?.(false);
    console.log(err);
  }
}

function* updatePolicyPages(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${STORE_POLICY_API.updatePolicyPages}`,
      data: actions.data,
    });
    actions?.callback?.(response.data.status);
  } catch (err) {
    actions?.callback?.(false);
  }
}

function* setItemOutOfStockVisiblity(actions: any): any {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}${OUT_OF_STOCK_API}`,
      data: actions.data,
    });
    actions?.callback?.(response.data.status);
  } catch (err) {
    actions?.callback?.(false);
  }
}

function* uploadCompressedImageToS3(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/settings/uploadCompressedImageToS3`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      actions && actions.callback(parsedData);
    } else {
      ToastNotifyError("Unable to upload image!");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    ToastNotifyError("Unable to upload image!");
    yield put(setPageLoaderVisibility(false));
  }
}

function* domainLandingCallBackRequest(actions) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: DOMAIN_API.addRequestToCallback,
      data: actions.data.data,
    });
    if (response && response?.status === 200) {
      actions.data.callback && actions.data.callback(response.status);
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError("Sending callback request");
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getSearchdSettingData(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: SETTING_PAGE_SEARCH_API.searchSetting(actions.data),
      data: actions.data.data,
      parseToJson: true,
    });
    if (response && response?.status) {
      yield put(saveSearchSettingData(response?.data));
    }
  } catch (err) {
    console.log(err);
    ToastNotifyError(err?.data?.message);
  }
}

function* fetchAppConstants(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: actions.isDLPCall ? COMMON_API.GET_DLP_APP_CONSTANTS : COMMON_API.GET_APP_CONSTANTS,
      parseToJson: true,
    });
    if (response?.data.status) {
      const data = response.data.data;
      yield put(saveAppConstants(data));
      actions.callback && actions.callback(data);
    }
  } catch (err) {
    ToastNotifyError("Facing some internal issue.");
  }
}

function* fetchRestrictionConfig(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: COMMON_API.getRestrictionConfig(),
      parseToJson: true,
    });
    if (response?.data.status) {
      const data = response.data.data;
      yield put(saveRestrictionConfig(data));
      actions.callback && actions.callback(data);
    }
  } catch (err) {
    ToastNotifyError("Something went wrong...");
  }
}

export default function* root() {
  yield takeEvery(IS_PREMIUM_SET, isPremiumSet);
  yield takeEvery(GET_ACCOUNT_INFO, getUserAccountInfo);
  yield takeEvery(PUSH_EVENT_LOG, pushEventLogFunc);
  yield takeEvery(GET_ALL_POLICY_PAGES, getAllPolicyPages);
  yield takeEvery(UPDATE_POLICY_PAGES, updatePolicyPages);
  yield takeEvery(OUT_OF_STOCK, setItemOutOfStockVisiblity);
  yield takeEvery(UPLOAD_COMPRESSED_IMAGE_TO_S3, uploadCompressedImageToS3);
  yield takeEvery(SEND_DOMAIN_CALLBACK_REQUEST, domainLandingCallBackRequest);
  yield takeEvery(GET_SEARCH_SETTING_DATA, getSearchdSettingData);
  yield takeEvery(GET_APP_CONSTANTS, fetchAppConstants);
  yield takeEvery(FETCH_RESTRICTIONS_CONFIG, fetchRestrictionConfig);
}
