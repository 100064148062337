import React from "react";
import { Button, ButtonProps as DefaultButtonProps, withStyles } from "@material-ui/core";
import { twMerge } from "tailwind-merge";

export type CustomButtonVariant =
  | "primary"
  | "secondary"
  | "secondary-2"
  | "secondaryFilled"
  | "secondaryFilled-2"
  | "tertiary";

interface CustomButtonProps {
  customVariant: CustomButtonVariant;
  customBgColor?: string;
}

interface Props extends Omit<DefaultButtonProps, "color" | "size" | "variant"> {
  image?: any;
  imageDirection?: "left" | "right";
  size?: "small" | "medium" | "large";
  customVariant?: CustomButtonVariant;
  customColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  showShadow?: boolean;
  removeHoverBg?: boolean;
  onMouseUp?: any;
  onMouseDown?: any;
}

const defaultStyleAttributes: React.CSSProperties = {
  textTransform: "none",
  padding: 0,
};

const defaultColors = {
  primary: {
    backgroundColor: "#2B74D8",
    color: "#ffffff",
    border: "1px solid #2B74D8",
  },
  secondary: {
    backgroundColor: "#ffffff",
    color: "#2B74D8",
    border: "1px solid #111C361F",
  },
  "secondary-2": {
    backgroundColor: "#ffffff",
    color: "#111C36",
    border: "1px solid #111C361F",
  },
  secondaryFilled: {
    backgroundColor: "#111C360F",
    color: "#2B74D8",
    border: "1px solid #111C361F",
  },
  "secondaryFilled-2": {
    backgroundColor: "#111C360F",
    color: "#111c36",
    border: "1px solid #111C361F",
  },
  tertiary: {
    backgroundColor: "transparent",
    color: "#2B74D8",
  },
};

const defaultGeometry: Record<string, React.CSSProperties> = {
  small: {
    fontSize: 10,
    padding: "4px 18px",
  },
  medium: {
    fontSize: 14,
    padding: "8px 24px",
  },
  large: {
    fontSize: 18,
    padding: "12px 30px",
  },
};

const CustomButton = withStyles(() => ({
  root: {
    display: "flex",
    transition: "all 250ms ease-in",
    fontFamily: "Inter",
    "&:hover": {
      opacity: 0.96,
      backgroundColor: (props: CustomButtonProps) => {
        return props.customBgColor || defaultColors[props.customVariant || "primary"].backgroundColor;
      },
    },
  },
}))((props: CustomButtonProps & DefaultButtonProps) => <Button {...props} />);

export default function CustomizedButton(props: Props) {
  const { imageDirection = "left", size = "medium", customVariant = "primary", removeHoverBg } = props;
  const stripButtonDefaults = customVariant === "tertiary";

  const dropShadow = (() => {
    if (!props.showShadow) {
      return "";
    }
    switch (customVariant) {
      case "secondary":
      case "secondary-2":
      case "secondaryFilled":
      case "secondaryFilled-2":
        return " !tw-shadow-[0px_2px_6px_0px_#0000001F] ";
      default:
        return "";
    }
  })();

  const getCustomBgColor = () => {
    if (removeHoverBg) {
      return "transparent";
    }
    return (
      props.className?.split?.(" tw-bg-[")?.[1]?.split?.("]")?.[0] ||
      props.className?.split?.(" !tw-bg-[")?.[1]?.split?.("]")?.[0] ||
      props.backgroundColor
    );
  };

  return (
    <CustomButton
      customVariant={customVariant}
      customBgColor={getCustomBgColor()}
      onClick={(event) => {
        event.stopPropagation();
        props?.onClick && props?.onClick(event);
      }}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      disabled={props.disabled}
      style={{
        ...defaultColors[customVariant],
        ...defaultStyleAttributes,
        ...props.borderColor ? { borderColor: props.borderColor } : {},
        ...!stripButtonDefaults ? defaultGeometry[size] : {},
        ...props.backgroundColor ? { backgroundColor: props.backgroundColor } : {},
      }}
      className={twMerge(
        "ds-font-inter tw-relative tw-flex tw-items-center tw-justify-center tw-gap-[10px] tw-font-semibold tw-leading-[1] tw-tracking-normal",
        imageDirection === "left" ? "!tw-flex-row " : "!tw-flex-row-reverse ",
        dropShadow,
        props.className,
      )}
      {...props}
    >
      {props.image && (typeof props.image === "object" ? props.image : <img src={props.image} alt="button image" />)}
      <p style={{ color: props.customColor }} className="!tw-z-[2] !tw-m-0">
        {props.children}
      </p>
    </CustomButton>
  );
}
